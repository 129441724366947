import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Component, EventEmitter, Input,Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ConfirmationService, MessageService,  MenuItem } from 'primeng/api';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { Dateformat,breadcrumnsCodes, AuditIdentifier, AuditLogEntities, AuditOperations, AuditLogModule, FrequencyValues, CodeList, CodeListStatusTypes } from 'src/app/shared/constants/global.constants';
import { LoaderService } from 'src/app/Loader/loader.service';
import {} from 'src/app/shared/Guards/can-deactivate-guard.service';
import { Observable} from 'rxjs';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { CodeListValues, ContractKpi, ContractKpiDataCollection, KPIThreshold, MasterKpiData } from '../../Model/contractKpi';
import { ContractKPIService } from '../../../Contract/Services/ContractKpi.Service';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { DatePipe,} from '@angular/common';
import { NotificationService } from 'src/app/shared/Notifications/notification.service';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { CommonService } from 'src/app/shared/Service/common.service';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { SponsorContact, SupplierContact } from 'src/app/MasterData/Services/supplier-profile';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { ChecknulloremptyComponent } from 'src/app/shared/checknullorempty/checknullorempty.component';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';
@Component({
  selector: 'app-con-edit-kpi',
  templateUrl: './con-edit-kpi.component.html',
  styleUrls: ['./con-edit-kpi.component.css']
})
export class ConEditKpiComponent {
  ingredient!: string;
  showDialog() {
    this.visible = true;
}
@Input() contractDetailId: any;
@Input() kpiId: any;
@Input() SupplierId: any;
@Input() SponsorId: any;
@Input() EngagementName:any;
@Output() cancelKpitButtonClick = new EventEmitter<void>();
@Output() saveKpiButtonClick = new EventEmitter<void>();
@Output() viewKpiButtonClick: EventEmitter<any> = new EventEmitter<any>();
  visible: boolean = false; 
  executeCanExist: boolean = true;
  breadcrumbs: MenuItem[]=[];
  _enumBreadcrumb :any;
  _dateformart :any;
   isLoading=false;
  kpiNameDataSource: CodeListValues[] | undefined;
  kpiReportingFrequencyDataSource: CodeListValues[] | undefined;
  kpiDataCollectionFrequencyDataSource: CodeListValues[] | undefined;
  statusDataSource: CodeListValues[] | undefined;
  hyperCareThresholdDataSource: CodeListValues[] | undefined;
  steadyStateThresholdDataSource: CodeListValues[] | undefined;
  masterKpiDataSource: MasterKpiData[] | undefined;
  _contractKpi=new ContractKpi();
  _kpiSteadyStateThresholdDetail= new KPIThreshold();
  _kpiSteadyStateThresholdDetails: KPIThreshold[]=[];
  _kpiHyperCareThresholdDetail= new KPIThreshold();
  _kpiHyperCareThresholdDetails: KPIThreshold[]=[];
  _dynamicKpiHyperCareThreshold= new KPIThreshold();
  _dynamicKpiHyperCareThresholds: KPIThreshold[]=[];
  _dynamicKpiSteadyStateThreshold= new KPIThreshold();
  _dynamicKpiSteadyStateThresholds: KPIThreshold[]=[];
  _readOnlyUnitofMeasure:string="";
  _readOnlyCategory:string="";
  _readOnlyComplianceIndicator:string="";
  _readOnlyCalculation:string="";
  _readOnlyAttribute1:string="";
  _readOnlyAttribute2:string="";
  _readOnlyAttribute3:string="";
  _readOnlyFormulaName:string="";
  _formulaeId:number=0;
  _kpiAttributeTypeCode:string="";
  _kpiAttributeTypeId:number=0;
  _readOnlySybmolHyperCareThreshold:string="";
  _readOnlySybmolSteadyStateThreshold:string="";
  _isNotApplicable:boolean=false;
  _unitCode:string="";
   submitted = false;
   isSaveBtnDisable = false;
    KpiMinEndDate:any;
    KpiMaxStartDate:any;
    hyperCareMinEndDate:any;
    hyperCareMaxStartDate:any;
    myContrctKPIForm: FormGroup;
    kpiDataCollectionFrom: FormGroup;
    ContractId:any;
    ContractKpiId:any;
    ContractName:any;
    _oldContractKpi:any
    _iskpiEdit:any;
    _contractKpiDataCollection= new ContractKpiDataCollection();
    _contractKpiDataCollections: ContractKpiDataCollection[]=[];
    selectedItem: any;
    oldSelectedItem: any;
    dataCollectionSubmitted=false;
    _overAllAverageKPI?:string="";
    _overallAverageHealth?:string="";
    suggestedKPIName:string="";
    kpiCreatedUserName:string="";
    kpiUpdatedUserName:string="";


    editedKPIDataCollection:any=[];//msg
    frequencyData:any;
    mitigationForm: FormGroup;
    listOfKpis:any;
    currentIndex: number = -1; 
  
    constructor(private router: Router, private fb: FormBuilder, private confirmationService: ConfirmationService,
       private messageService: MessageService, private loaderService: LoaderService,
        private breadcrumbService: BreadcrumbService,private masterDataService: SponserService,
        private service: ContractKPIService, private _contractservice: ContractService,
        private activeRouter: ActivatedRoute, private _contractKPIService:ContractKPIService,private datePipe: DatePipe, private notificationService:NotificationService,private auditLogService: AuditLogService,
        private privilegeService:PrivilegeService,private commonService:CommonService,private currencyService: CurrencyService,private supplierService: MasterSupplierService,private engagementService: EngagementService,private checkNullOrEmptyValuesService: ChecknulloremptyComponent,private tabAndStateService: TabAndStateService){
        
          // Common validators
const requiredValidator = [Validators.required];
const minLengthValidator = (length: number) => [Validators.minLength(length)];
const maxLengthValidator = (length: number) => [Validators.maxLength(length)];
const patternValidator = (pattern: string | RegExp) => [Validators.pattern(pattern)];
const noOnlySpacesValidator = this.noOnlySpacesValidator; // Assuming it's defined in your component

// Common form controls
const disabledControl = (value: any = '') => [{ value, disabled: true }];
const enabledControl = (value: any = '', validators: any[] = []) => [value, validators];

// Group initialization
this.myContrctKPIForm = this.fb.group({
    _KPIName: disabledControl(),
    _kpiDataCollectionFrequency: disabledControl(),
    _kpiReportingFrequency: enabledControl('', requiredValidator),
    _status: enabledControl('', requiredValidator),
    _unitOfMeasure: disabledControl(),
  
    _hyperCareThreshold: enabledControl(null, this._isNotApplicable ? [] : requiredValidator),
    //_hyperCareThresholdValue: enabledControl('', this._isNotApplicable ? [] : [...requiredValidator, ...minLengthValidator(1), ...maxLengthValidator(3)]),
    _hyperCareThresholdValue:['',[this._isNotApplicable ? null : Validators.required,Validators.minLength(1)]],
    _hyperCareThresholdsymbol: disabledControl(),
    _hyperCareStartDate: disabledControl(),
    _hyperCareEndDate: disabledControl(),

    _dyhyperCareThreshold: enabledControl(''),
    _isNotApplicable: enabledControl(''),
    _steadyStateThreshold: enabledControl('', requiredValidator),
    _dysteadyStateThreshold: enabledControl(''),
    _dyhyperCareThresholdValue: enabledControl(''),
    _dyhyperCareThresholdsymbol: disabledControl(),
  
   // _steadyStateThresoldValue: enabledControl('', [...requiredValidator, ...minLengthValidator(1), ...maxLengthValidator(3)]),
   _steadyStateThresoldValue:['',[Validators.required,Validators.minLength(1)]],
   _steadyStateThresoldSymbol: disabledControl(),
    _dysteadyStateThresoldValue: enabledControl(''),
    _dysteadyStateThresoldSymbol: disabledControl(),

    _category: disabledControl(),
    _complianceIndicator: disabledControl(),
    _calculation: disabledControl(),

    _attribute1: enabledControl('', [...requiredValidator, ...minLengthValidator(3), noOnlySpacesValidator, ...patternValidator(/^[^&%]*$/)]),
    _attribute2: enabledControl('', [...requiredValidator, ...minLengthValidator(3), noOnlySpacesValidator, ...patternValidator(/^[^&%]*$/)]),
    _attribute3: enabledControl(''),

    _kpiDescription: enabledControl('', maxLengthValidator(300)),
    _serviceName: enabledControl('', [...requiredValidator, ...maxLengthValidator(200), noOnlySpacesValidator, ...patternValidator(/^[^&%]*$/)]),
    _kpiName: enabledControl('', [...requiredValidator, ...minLengthValidator(3), ...maxLengthValidator(400), noOnlySpacesValidator, ...patternValidator(/^[^&%]*$/)]),

    _frequencyValueforStartDate: disabledControl(),
    _kpiStartDateYear: disabledControl(),
    _frequencyValueforEndDate: enabledControl('', requiredValidator),
    _kpiEndDateYear: enabledControl('', [...requiredValidator, ...minLengthValidator(3)]),
});

// Data collection form
this.kpiDataCollectionFrom = this.fb.group({
    _calculatedKPI: enabledControl('', requiredValidator),
    _remarks: enabledControl('', requiredValidator),
    _attribute1: enabledControl('', requiredValidator),
    _attribute2: enabledControl('', requiredValidator),
    _attribute3: enabledControl(''),
    _health: enabledControl(''),
});

// Mitigation form
this.mitigationForm = this.fb.group({
    Action: enabledControl('', requiredValidator),
    Status: enabledControl('', requiredValidator),
});
}

    
    noOnlySpacesValidator(control: AbstractControl): ValidationErrors | null {
      if (control.value && control.value.trim().length === 0) {
        return { onlySpaces: true };
      }
      return null;
    }
    auditIdentifierDetails:any;
    auditEntities:any;
    auditOperations:any;
    auditModule:any;
    codeListValues:any;
    codeListStatusTypes:any;
    addMitigationSaved = false;
    ngOnInit() {
  
      this.loaderService.showLoader();
      this.loaderService.hidefinalLoader();
     
      this.ContractKpiId=this.kpiId;
      this.ContractId=this.contractDetailId;
      this.loaderService.hidefinalLoader();
      this.KpiMinEndDate=null;
      this.KpiMaxStartDate=null;
      this.hyperCareMinEndDate=null;
      this.hyperCareMaxStartDate=null;
      this._enumBreadcrumb=breadcrumnsCodes;
      this._dateformart=Dateformat;
      this.auditIdentifierDetails=AuditIdentifier; 
      this.auditEntities=AuditLogEntities;
      this.auditOperations=AuditOperations;
      this.auditModule=AuditLogModule;

      this.GetKpiNames(7);
      this.GetKpiReportingFrequencies(9) ;
      this.GetStatus(3);
      this.GetAllHyperCareThresholds(10);
      this.GetMasterKpis(12);
      this.GetContractDataById(this.ContractId)
      this.GetContractKpiById(this.ContractKpiId);
      this.codeListValues=CodeList;
      this.codeListStatusTypes=CodeListStatusTypes
      this.GetMitigationStatus(this.codeListValues.Tbl_Status, this.codeListStatusTypes.MASTS);
      this.formOldContractKpi();
      this.listOfKpis=this.tabAndStateService.getKpiData();
      this.currentIndex = this.listOfKpis.findIndex((a: { id: any; }) => a.id ==this.ContractKpiId);
  }

  
  GetKpiNames(type:number) {
    this.masterDataService.getCodeListData(type,'ContractKPI').subscribe(
      res=>{
        this.kpiNameDataSource=res.data;
      }
    );
  };
  
  GetKpiReportingFrequencies(type:number) {
    this.masterDataService.getCodeListData(type,'').subscribe(
      res=>{
        this.kpiReportingFrequencyDataSource=res.data;
      }
    );
  };

  isFrequencyAnnual=false;
  
  GetkpiDataCollectionFrequenies(type:number) {
    this.masterDataService.getCodeListData(type,'').subscribe(
      res=>{
        this.kpiDataCollectionFrequencyDataSource=res.data;

        let frequency=this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==this._contractKpi.kpiDataCollectionFrequencyId)[0].name;
        const kpiStart=this.commonService.getFrequencyFromDate(this._contractKpi.kpiStartDate,frequency);
        const kpiEnd=this.commonService.getFrequencyFromDate(this._contractKpi.kpiEndDate,frequency);
        
        //Get Frequency Data Here
        if(frequency=="Monthly")
          this.frequencyData=JSON.parse(FrequencyValues.Monthly);
        else if(frequency=="Quarterly")
          this.frequencyData=JSON.parse(FrequencyValues.Quarterly);
        else if(frequency=="Half-Year")
          this.frequencyData=JSON.parse(FrequencyValues.HalfYearly);
        else if(frequency=="Annual")
          this.frequencyData=JSON.parse(FrequencyValues.Annual);

          if(frequency=="Annual"){
            this.isFrequencyAnnual=true;
          }
          else{
            this.isFrequencyAnnual=false;
          }

          this.SetFrequencyDataCollection(this._contractKpi.kpiDataCollectionFrequencyId);

          this.BindingFrequencyDates(kpiStart,kpiEnd,frequency,this.frequencyData);
      }
    );
  };
  GetStatus(type:number) {
    this.masterDataService.getCodeListData(type,'KPI Status').subscribe(
      res=>{
        this.statusDataSource=res.data;
      }
    );
  };
  GetAllHyperCareThresholds(type:number) {
    this.masterDataService.getCodeListData(type,'').subscribe(
      res=>{
        this.hyperCareThresholdDataSource=res.data;
        this.steadyStateThresholdDataSource=res.data;
      }
    );
  };
  GetMasterKpis(type:number) {
    this.masterDataService.GetAllSupplierMasterData(type).subscribe(
      res=>{
        this.masterKpiDataSource=res.data;
      }
    );
  };
  OnChangeKPI(kpiDetailId:number){
    let filteSupplierMasterData = this.masterKpiDataSource?.filter(t=>t.kpiDetailId==kpiDetailId);
    if(filteSupplierMasterData!=null)
    {
  this._readOnlyUnitofMeasure=filteSupplierMasterData[0].unitOfMeasure;
  this._readOnlyCategory=filteSupplierMasterData[0].category;
  this._readOnlyComplianceIndicator=filteSupplierMasterData[0].complianceIndicator;
  this._unitCode=filteSupplierMasterData[0].unitOfMeasureCode;
  this._formulaeId=filteSupplierMasterData[0].formulaeId
  this._readOnlyFormulaName=filteSupplierMasterData[0].formulaName;
  this._kpiAttributeTypeCode=filteSupplierMasterData[0].kpiAttributeTypeCode;
  this._kpiAttributeTypeId=filteSupplierMasterData[0].kpiAttributeTypeId;
  
  if(!this._isNotApplicable)
  {
    this._readOnlySybmolHyperCareThreshold=this._unitCode;
  }
  else{
    this._readOnlySybmolHyperCareThreshold="";
  }
  this._readOnlySybmolSteadyStateThreshold=this._unitCode;
  
  }
   else{  
    this._readOnlyUnitofMeasure="";
    this._readOnlyCategory="";
    this._readOnlyComplianceIndicator="";
    this._readOnlyCalculation="";
    this._readOnlyAttribute1="";
    this._readOnlyAttribute2="";
    this._readOnlyAttribute3="";
    this._readOnlySybmolHyperCareThreshold="";
    this._readOnlySybmolSteadyStateThreshold="";
    this._unitCode="";
    this._formulaeId=0;
    this._readOnlyFormulaName="";
    this._kpiAttributeTypeCode="";
    this._kpiAttributeTypeId=0;
    }
  }

onUpdateContractKpi() {
  this.clearErrorsIfNotApplicable();
  this.clearErrorsForAnnualFrequency();
  this.validateDynamicFields();
  
  this.submitted = true;
  this.disableSaveButtonTemporarily();

  this.updateValidators('_attribute1');
  this.updateValidators('_attribute2');

  if (this.myContrctKPIForm.valid) {
      this.loaderService.showLoader();
      this.prepareContractKpiData();

      if (this.isStartDateGreaterThanEndDate()) {
          this.showWarning('KPI Tracking End should be greater than KPI Tracking Start');
      } else if (this.isEndDateShortened()) {
          this.showWarning('KPI Tracking End can only be extended and cannot be shortened');
      } else {
          this.saveContractKpi();
      }
  } else {
      this.showWarning('Please enter required information');
  }
}

private clearErrorsIfNotApplicable() {
  if (this._isNotApplicable) {
      this.clearFormControlErrors([
          '_hyperCareStartDate', '_hyperCareEndDate', '_hyperCareThresholdValue', '_hyperCareThreshold'
      ]);
  }
}

private clearErrorsForAnnualFrequency() {
  if (this.DataCollectionFrequency === "Annual") {
      this.clearFormControlErrors([
          '_frequencyValueforStartDate', '_frequencyValueforEndDate'
      ]);
  }
}

private clearFormControlErrors(controls: string[]) {
  controls.forEach(control => {
      this.myContrctKPIForm.controls[control].setErrors(null);
  });
}

private validateDynamicFields() {
  if (this._dynamicKpiHyperCareThresholds?.length) {
      this.setDynamicFieldErrors(
          '_dyhyperCareThresholdValue', 
          this._dynamicKpiHyperCareThreshold.percentageValue
      );
      this.setDynamicFieldErrors(
          '_dyhyperCareThreshold', 
          this._dynamicKpiHyperCareThreshold.mathematicalSymbolId
      );
  }

  if (this._dynamicKpiSteadyStateThresholds?.length) {
      this.setDynamicFieldErrors(
          '_dysteadyStateThresoldValue', 
          this._dynamicKpiSteadyStateThreshold.percentageValue
      );
      this.setDynamicFieldErrors(
          '_dysteadyStateThreshold', 
          this._dynamicKpiSteadyStateThreshold.mathematicalSymbolId
      );
  }
}

private setDynamicFieldErrors(controlName: string, value: any) {
  const control = this.myContrctKPIForm.controls[controlName];
  control.setErrors(value ? null : { required: true });
}

private disableSaveButtonTemporarily() {
  this.isSaveBtnDisable = true;
  setTimeout(() => this.isSaveBtnDisable = false, 3000);
}

private prepareContractKpiData() {
  this._contractKpiDataCollections.forEach(collection => {
      collection.contractKpiMitigationActionsList = collection.contractKpiMitigationActionsList?.map(
          action => this.updateMitigationActions(action)
      );
  });

  this.updateKpiThresholdDetails();
  this._contractKpi.contractDetailId = this.ContractId;
  this._contractKpi.kpiReportingFrequencyCode = this.getKpiReportingFrequencyCode();
  this._contractKpi.updatedBy = this.privilegeService.getLoginUserId();
  this._contractKpi.kpiDataCollection = this._contractKpiDataCollections;
  this.formatHyperCareDates();
  this.assignAttributesToContractKpi();
  this.assignFrequencyDatesToContractKpi();
}

private updateKpiThresholdDetails() {
  if (!this._isNotApplicable) {
      this._kpiHyperCareThresholdDetails.push(this._kpiHyperCareThresholdDetail);
      if (this._dynamicKpiHyperCareThreshold.mathematicalSymbolId !== 0) {
          this._kpiHyperCareThresholdDetails.push(this._dynamicKpiHyperCareThreshold);
      }
  }

  this._kpiSteadyStateThresholdDetails.push(this._kpiSteadyStateThresholdDetail);
  if (this._dynamicKpiSteadyStateThreshold.mathematicalSymbolId !== 0) {
      this._kpiSteadyStateThresholdDetails.push(this._dynamicKpiSteadyStateThreshold);
  }

  this._contractKpi.KPIHyperCareThresholdDetail = this._kpiHyperCareThresholdDetails;
  this._contractKpi.KPISteadyStateThresholdDetail = this._kpiSteadyStateThresholdDetails;
}

private getKpiReportingFrequencyCode() {
  return this.kpiDataCollectionFrequencyDataSource?.find(t => t.id === this._contractKpi.kpiDataCollectionFrequencyId)?.code || '';
}

private formatHyperCareDates() {
  this._contractKpi.hyperCareEndDate = this.formatDate(this._contractKpi.hyperCareEndDate);
  this._contractKpi.hyperCareStartDate = this.formatDate(this._contractKpi.hyperCareStartDate);
}

private formatDate(date: any) {
  return date ? this.datePipe.transform(date, "MM/dd/yyyy") : null;
}

private assignAttributesToContractKpi() {
  this._contractKpi.attribute1 = this._readOnlyAttribute1;
  this._contractKpi.attribute2 = this._readOnlyAttribute2;
  this._contractKpi.attribute3 = this._readOnlyAttribute3;
  this._contractKpi.calculation = this._readOnlyCalculation;
}

private assignFrequencyDatesToContractKpi() {
  const frequency = this.kpiDataCollectionFrequencyDataSource?.find(t => t.id === this._contractKpi.kpiDataCollectionFrequencyId)?.name;
  this._contractKpi.kpiStartDate = this.commonService.getCompleteDate(frequency, this._contractKpi.frequencyValueforStartDate, this._contractKpi.kpiStartDateYear);
  this._contractKpi.kpiEndDate = this.commonService.getCompleteDate(frequency, this._contractKpi.frequencyValueforEndDate, this._contractKpi.kpiEndDateYear);
}

private isStartDateGreaterThanEndDate(): boolean {
  return this._contractKpi.kpiStartDate > this._contractKpi.kpiEndDate;
}

private isEndDateShortened(): boolean {
  return this._contractKpi.kpiEndDate < new Date(this.previousEndDate);
}

private showWarning(summary: string) {
  this.messageService.add({ key: 'tc', life: 4000, severity: 'warn', summary, detail: '' });
  this.loaderService.hideLoader();
}

private async saveContractKpi() {
  this._contractKpi.kpiEndDate = this.formatDate(this._contractKpi.kpiEndDate);
  this._contractKpi.kpiStartDate = this.formatDate(this._contractKpi.kpiStartDate);

  this.service.UpdateContractKpi(this._contractKpi).subscribe(async res => {
      if (res.status) {
          this.loaderService.hideLoader();
          this.compareMitigationActions();
          await this.sendKpiThresholdNotification();
          this.messageService.add({ key: 'tc', severity: 'success', summary: 'Changes have been saved', detail: '' });
          this.compareModels();
          this.emitViewKpiButtonClick();
      }
  });
}

private async sendKpiThresholdNotification() {
  if (this.editedKPIDataCollection && this.editedKPIDataCollection.length > 0) {
      const kpiData = {
          kpiName: this._contractKpi.kpiName,
          engagementName: this.EngagementName,
          contractTitle: this.ContractName,
          supplierId: 0,
          contractId: this.ContractId,
          kpiId: this.kpiId,
          kpiDataCollectionEdited: this.editedKPIDataCollection,
          kpiDataCollection: this._contractKpiDataCollections,
          attribute1: this._contractKpi.attribute1,
          attribute2: this._contractKpi.attribute2,
          attribute3: this._contractKpi.attribute3,
      };
      await this.notificationService.KPIThresholdNotification(this.kpiId, kpiData, 0, this.SponsorId, this.SupplierId);
  }
}

private emitViewKpiButtonClick() {
  const _dataResult = { ContractId: this.ContractId, kpiId: this.kpiId };
  this.viewKpiButtonClick.emit(_dataResult);
}

  updateMitigationActions(action: any): any {
    return {
      id: action.Id ?? action.id ?? null,
      action: action.Action ?? action.action ?? '',
      actionOwnerId: action.ActionOwner ?? action.actionOwnerId ?? null,
      targetDate: this.datePipe.transform(action.TargetDate ?? action.targetDate, 'MM/dd/yyyy') ?? null,
      statusMappingId: action.StatusMappingId ?? action.statusMappingId ?? null,
      closureDate: this.datePipe.transform(action.ClosureDate ?? action.closureDate, 'MM/dd/yyyy') ?? null,
      isDeleted: action.IsDeleted ?? action.isDeleted ?? false
    };
  }   
  onCancelContractKpi()
  {
    const dataResult = {
      ContractId:this.ContractId,
      kpiId:this.kpiId,
    };
     if ((this._oldContractKpi!=JSON.stringify(this._contractKpi)))
     {
    
      this.confirmationService.confirm({
        message: 'Changes will not be saved, Do you want to Continue?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: 'Changes not saved',
            detail: '',
        });
        this.viewKpiButtonClick.emit(dataResult);
           },
           reject: () => {
           },
         });
     }
     else{
       this.viewKpiButtonClick.emit(dataResult);
     }


  }
  StartDateSelection(startDate:any,type:number){
    this.hyperCareMinEndDate= new Date(startDate);
    this.hyperCareMinEndDate.setDate(this.hyperCareMinEndDate.getDate() + 1);
  }
  EndDateSelection(endDate:any,type:number){
    if(type==1)
    {
      this.KpiMaxStartDate= new Date(endDate);
      this.KpiMaxStartDate.setDate(this.KpiMaxStartDate.getDate() - 1);
    }
    else{
      this.hyperCareMaxStartDate= new Date(endDate);
      this.hyperCareMaxStartDate.setDate(this.hyperCareMaxStartDate.getDate() - 1);
    }
     
  }
  addRow(type: number) {
    const newThreshold = {
      Id: 0,
      percentageValue: '',
      mathematicalSymbolId: 0,
      contractKPIId: 0,
    };
  
    if (type === 1) {
      this._dynamicKpiHyperCareThresholds.push(newThreshold);
    } else {
      this._dynamicKpiSteadyStateThresholds.push(newThreshold);
    }
  }

  removeRow(index: number, type: number) {
    if (type === 1) {
      this._dynamicKpiHyperCareThresholds.splice(index, 1);
      this.clearThresholdErrors('_dyhyperCareThresholdValue', '_dyhyperCareThreshold');
    } else {
      this._dynamicKpiSteadyStateThresholds.splice(index, 1);
      this.clearThresholdErrors('_dysteadyStateThresoldValue', '_dysteadyStateThreshold');
    }
    this.resetKpiThreshold(type);
  }
  
  clearThresholdErrors(thresholdValueControl: string, thresholdControl: string) {
    this.myContrctKPIForm.controls[thresholdValueControl].setErrors(null);
    this.myContrctKPIForm.controls[thresholdControl].setErrors(null);
  }
  
  resetKpiThreshold(type: number) {
    if (type === 1) {
      this._dynamicKpiHyperCareThreshold = new KPIThreshold();
    } else {
      this._dynamicKpiSteadyStateThreshold = new KPIThreshold();
    }
  }  
  GetContractDataById(ContractId:number){
    this._contractservice.GetContractById(ContractId).subscribe(
      res=>{
        this.loaderService.hideLoader();
        this.ContractName=res.data.title;
        this.GetEngagementDataById(res.data.engagementId);
      }
    );
  }
  GetEngagementDataById(EngagementId:number){
    this.engagementService.GetEngagementById(EngagementId).subscribe(
      res=>{
        this.loaderService.hideLoader();
        this.CombinedContacts(res.data.supplierId,res.data.sponsorId);
      }
    );
  }
  canExist(): boolean | Promise<boolean> | Observable<boolean> {
    if (this.executeCanExist &&(this._oldContractKpi!=JSON.stringify(this._contractKpi)))
    {
      return new Promise<boolean>((resolve) => {
        this.confirmationService.confirm({
          message: 'Changes will not be saved, Do you want to Continue?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: 'Changes not saved',
              detail: '',
          });
            resolve(true); 
          },
          reject: () => {
            resolve(false); 
          },
        });
      });
    }
    else{
      return true;
    }
    
  }
  generateBreadcrumb(component?:string,recordName?:string,tabName?:string,childRecName?:string,recordId?:number)
  {
    this.breadcrumbService.generateBreadcrumb(component,recordName,tabName,childRecName,recordId);
  }
  
  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }
  formOldContractKpi(){
    setTimeout(() => {
      this._oldContractKpi=JSON.stringify(this._contractKpi);
      this._iskpiEdit=true;
    }, 2000);
  }
  onNumericInput(event: Event,_controlname:string) {
    const inputElement = event.target as HTMLInputElement;
    const sanitizedValue = inputElement.value.replace(/\D/g, '');
    inputElement.value = sanitizedValue;
    this.myContrctKPIForm.controls[_controlname].setValue(sanitizedValue);
  }

  onNumericInput_ForTargetValue(event: Event,_controlname:string) {
    const sanitizedValue= this.commonService.onNumericInput_ForTargetValue_KPI(event);
    this.myContrctKPIForm.controls[_controlname].setValue(sanitizedValue);
  }
  

  oldKpiInfo:any;
  previousEndDate:any;
  DataCollectionFrequency:string="";
  isAttribute1NotApplicable=false;
isAttribute2NotApplicable=false;
isAttribute3NotApplicable=false;
oldMitigationActions:any[]=[];
  GetContractKpiById(ContractKpiId:number) {
    this.loaderService.showLoader();
    this.service.GetContractKpiById(ContractKpiId).subscribe(
      res=>{
        this._contractKpi=res.data;
        this.previousEndDate=res.data.kpiEndDate;
        this._contractKpi.kpiStartDate=new Date(res.data.kpiStartDate);
        this._contractKpi.kpiEndDate=new Date(res.data.kpiEndDate);
        this._contractKpi.hyperCareStartDate=new Date(res.data.hyperCareStartDate);
        this._contractKpi.hyperCareEndDate=new Date(res.data.hyperCareEndDate);

        if(this._contractKpi.attribute1 == 'NA')
          {
          this.isAttribute1NotApplicable=true;
          }
          else{
            this.isAttribute1NotApplicable=false;
          }
        if(this._contractKpi.attribute2 == 'NA')
          {
            this.isAttribute2NotApplicable=true;
          }
          else{
            this.isAttribute2NotApplicable=false;
          }
        if(this._contractKpi.attribute3 == 'NA')
          {
              this.isAttribute3NotApplicable=true;
          }
          else{
            this.isAttribute3NotApplicable=false;
          }

        this._readOnlyCalculation=res.data.calculation?res.data.calculation:"";
        this._readOnlyAttribute1=res.data.attribute1?res.data.attribute1:"";
        this._readOnlyAttribute2=res.data.attribute2?res.data.attribute2:"";
        this._readOnlyAttribute3=res.data.attribute3?res.data.attribute3:"";


        this._contractKpiDataCollections=res.data.dataCollectionList;
        this._contractKpiDataCollections.forEach((item: any) => {
          this.oldMitigationActions= item.contractKpiMitigationActionsList;
          item.findingsCount = item.contractKpiMitigationActionsList ? item.contractKpiMitigationActionsList.length : 0;
        });
        
     

        this.GetkpiDataCollectionFrequenies(8);

        this._overAllAverageKPI=res.data.overAllAverageKPI;
        this.kpiCreatedUserName=this.privilegeService.getUserName(res.data.createdBy); 
        this.kpiUpdatedUserName=this.privilegeService.getUserName(res.data.updatedBy); 
        this._overallAverageHealth=res.data.overallAverageHealth
        if(res.data.kpiHyperCareThresholdDetail?.length==1)
        {
        this._kpiHyperCareThresholdDetail=res.data.kpiHyperCareThresholdDetail[0];
        }
        else if(res.data.kpiHyperCareThresholdDetail?.length==2){
        this._kpiHyperCareThresholdDetail=res.data.kpiHyperCareThresholdDetail[0];
        this._dynamicKpiHyperCareThresholds.push(res.data.kpiHyperCareThresholdDetail[1]);
        this._dynamicKpiHyperCareThreshold=res.data.kpiHyperCareThresholdDetail[1];
        }
        else{
         this._isNotApplicable=true;
         this._contractKpi.hyperCareStartDate=undefined;
         this._contractKpi.hyperCareEndDate=undefined;
        }
        if(res.data.kpiSteadyStateThresholdDetail?.length<2)
        {
        this._kpiSteadyStateThresholdDetail=res.data.kpiSteadyStateThresholdDetail[0];
        }
        else{
        this._kpiSteadyStateThresholdDetail=res.data.kpiSteadyStateThresholdDetail[0];
        this._dynamicKpiSteadyStateThresholds.push(res.data.kpiSteadyStateThresholdDetail[1]);
        this._dynamicKpiSteadyStateThreshold=res.data.kpiSteadyStateThresholdDetail[1];
        }
        setTimeout(() => {
          this.OnChangeKPI(res.data.kpiDetailId);
          let filteDataCollection = this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==this._contractKpi.kpiDataCollectionFrequencyId);
          if(filteDataCollection!=null)
          {
            this.DataCollectionFrequency=filteDataCollection[0].name;
          let selectedSubCode=filteDataCollection[0].sub_Code;
          this.kpiReportingFrequencyDataSource=this.kpiReportingFrequencyDataSource?.filter(t=>t.sub_Code>=selectedSubCode)
          }
         
        },1000);
        this.oldKpiInfo=res.data;
        this. oldKpiInfo.dataCollectionList=res.data.dataCollectionList;

        this.oldKpiInfo.overAllAverageKPI=res.data.overAllAverageKPI;
        this.oldKpiInfo.overallAverageHealth=res.data.overallAverageHealth
        this.oldContractKPIData=JSON.stringify(this.oldKpiInfo);

        this.oldKpiInfo.kpiEndDate = new Date(this.oldKpiInfo.kpiEndDate).toLocaleDateString(); 
        this.contractKPIDetailBeforeUpdate=JSON.stringify(this.oldKpiInfo);
        this.loaderService.hideLoader();
      }
    );
   
  };


  SetFrequencyDataCollection(frequencyId:any){
    let frequency=this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==frequencyId)[0].name;
        
    this._contractKpiDataCollections.forEach((item:any) => {
      item.dataCollectionFrequencyValue=this.commonService.getFrequencyFromDate(item.dataCollectionDate,frequency);
    });
  }

  BindingFrequencyDates(startFreq: any, endFreq: any, frequency: any, dataCollectionFrequencyData:any) 
  {
    let startFrequencyValue: string;
    let startFrequencyYear: string;
    let endFrequencyValue: string;
    let endFrequencyYear: string;
  
    switch (frequency) {
      case "Monthly":
        [startFrequencyValue, startFrequencyYear] = startFreq.split(" ");
        [endFrequencyValue, endFrequencyYear] = endFreq.split(" ");
        break;
      case "Quarterly":
      case "Half-Year":
      case "Annual":
        startFrequencyValue = startFreq.substring(0, 2); // Extract the first two characters
        startFrequencyYear = startFreq.slice(-4);
        endFrequencyValue = endFreq.substring(0, 2); // Extract the first two characters
        endFrequencyYear = endFreq.slice(-4);
        break;
      default:
        throw new Error('Invalid frequency');
    }

    if(frequency != "Annual"){
      this._contractKpi.frequencyValueforStartDate=dataCollectionFrequencyData.find((a:any)=>a.name==startFrequencyValue).order;
      this._contractKpi.frequencyValueforEndDate=dataCollectionFrequencyData.find((a:any)=>a.name==endFrequencyValue).order;;
    }

  this._contractKpi.kpiStartDateYear=startFrequencyYear;
  this._contractKpi.kpiEndDateYear=endFrequencyYear;
    
  }

  //Data collection SubGridOperations
  isAttributeValueSelected=false;
  onRadioButtonChange(KPIoption: string) {

    this.isAttributeValueSelected = KPIoption === '2';
    this.dataCollectionSubmitted = false;
  
    const resetAttributes = () => {
      this._contractKpiDataCollection.attribute1 = "";
      this._contractKpiDataCollection.attribute2 = "";
      this._contractKpiDataCollection.attribute3 = "";
      if (this._readOnlyFormulaName !== 'N/A') {
        this._contractKpiDataCollection.calculatedKPI = "";
      }
      this.kpiDataCollectionFrom.controls['_attribute1'].setErrors(null);
      this.kpiDataCollectionFrom.controls['_attribute2'].setErrors(null);
      this.kpiDataCollectionFrom.controls['_attribute3'].setErrors(null);
      this.kpiDataCollectionFrom.get('_attribute1')?.disable();
      this.kpiDataCollectionFrom.get('_attribute2')?.disable();
      this.kpiDataCollectionFrom.get('_attribute3')?.disable();
      this.kpiDataCollectionFrom.get('_calculatedKPI')?.disable();
    };
  
    if (KPIoption === '1') {
      resetAttributes();
      this.kpiDataCollectionFrom.controls['_calculatedKPI'].setErrors({ required: true });
      this.kpiDataCollectionFrom.get('_calculatedKPI')?.enable();
    } else if (KPIoption === '2') {
      this.kpiDataCollectionFrom.get('_attribute1')?.enable();
      this.kpiDataCollectionFrom.get('_attribute2')?.enable();
      
      if (this._readOnlyFormulaName !== 'N/A') {
        this._contractKpiDataCollection.calculatedKPI = "";
      }
  
      if (this._readOnlyAttribute3 && this._readOnlyFormulaName !== 'N/A') {
        this.kpiDataCollectionFrom.controls['_attribute3'].setErrors({ required: true });
        this.kpiDataCollectionFrom.get('_attribute3')?.enable();
      } else {
        this.kpiDataCollectionFrom.get('_attribute3')?.disable();
      }
  
      if (this._readOnlyAttribute1 && this._readOnlyFormulaName !== 'N/A') {
        this.kpiDataCollectionFrom.controls['_attribute1'].setErrors({ required: true });
      }
  
      if (this._readOnlyAttribute2 && this._readOnlyFormulaName !== 'N/A') {
        this.kpiDataCollectionFrom.controls['_attribute2'].setErrors({ required: true });
      }
    } else {
      resetAttributes();
    }
  }

  onEditDataCollection(item: any) {
    this._contractKpiDataCollection = new ContractKpiDataCollection();
    this.dataCollectionSubmitted = false;
    this.visible = true;
    this.selectedItem = { ...item };
    this.oldSelectedItem = { ...item };
  
    const resetAttributes = () => {
      for (let i = 1; i <= 3; i++) {
        const attributeName = `attribute${i}`;
        this.selectedItem[attributeName] = this.selectedItem[attributeName] ? this.selectedItem[attributeName] : null;
      }
    };
  
    if (this._kpiAttributeTypeCode === "INT") {
      if (this._contractKpiDataCollection.inputAttributeValueType == 1) {
        this.kpiDataCollectionFrom.get('_calculatedKPI')?.enable();
      }
  
      this.selectedItem.attribute1 = this.selectedItem.attribute1 || "";
      this.selectedItem.attribute2 = this.selectedItem.attribute2 || "";
      this.selectedItem.attribute3 = this.selectedItem.attribute3 || "";
      
      this.selectedItem.calculatedKPI = (this.selectedItem.attribute1 === "" && this.selectedItem.calculatedKPI == null) ? "" : this.selectedItem.calculatedKPI;
      
      this._contractKpiDataCollection = this.selectedItem;
      resetAttributes();
      
      this.kpiDataCollectionFrom.get('_attribute1')?.enable();
      this.kpiDataCollectionFrom.get('_attribute2')?.enable();
  
      this.isAttributeValueSelected = this._contractKpiDataCollection.inputAttributeValueType == 2;
    }
  
    if (this._kpiAttributeTypeCode === "DTE") {
      this.selectedItem.attribute1 = this.selectedItem.attribute1 ? new Date(this.selectedItem.attribute1) : "";
      this.selectedItem.attribute2 = this.selectedItem.attribute2 ? new Date(this.selectedItem.attribute2) : "";
      this.selectedItem.attribute3 = this.selectedItem.attribute3 ? new Date(this.selectedItem.attribute3) : "";
  
      this.selectedItem.calculatedKPI = (this.selectedItem.attribute1 === "" && this.selectedItem.calculatedKPI == null) ? "" : this.selectedItem.calculatedKPI;
      
      this._contractKpiDataCollection = this.selectedItem;
  
      if (this._contractKpiDataCollection.inputAttributeValueType == 1) {
        this.kpiDataCollectionFrom.get('_attribute1')?.disable();
        this.kpiDataCollectionFrom.get('_attribute2')?.disable();
        this.kpiDataCollectionFrom.get('_attribute3')?.disable();
        resetAttributes();
      } else {
        this.kpiDataCollectionFrom.get('_attribute1')?.disable();
        this.kpiDataCollectionFrom.get('_attribute2')?.disable();
        this.kpiDataCollectionFrom.get('_attribute3')?.disable();
        this.kpiDataCollectionFrom.get('_calculatedKPI')?.disable();
        resetAttributes();
      }
    }
  
    if (this._kpiAttributeTypeCode === "NA") {
      this.selectedItem.attribute1 = this.selectedItem.attribute1 || "";
      this.selectedItem.attribute2 = this.selectedItem.attribute2 || "";
      this.selectedItem.attribute3 = this.selectedItem.attribute3 || "";
  
      this.selectedItem.calculatedKPI = this.selectedItem.calculatedKPI == null ? "" : this.selectedItem.calculatedKPI;
      
      this._contractKpiDataCollection = this.selectedItem;
      this.isAttributeValueSelected = false;
    }
  }
  
  cancelDialog() {
    this.visible = false;
    this.messageService.add({
      key: 'tc',
      severity: 'info',
      summary: 'KPI data collection has not been saved',
      detail: '',
  });
  }

  isBtnClicked=false;
  btnCalculateKPI(){
    
      // Attribute3 should be considered here when attribute3 will be included in the business case
      if(this._contractKpiDataCollection.attribute1 != '' && this._contractKpiDataCollection.attribute2 != ""){
      this.CalKPI();
      }
      else
      {
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Enter the required Attribute values', detail: '' });
      }
   
  }

  isSaveKpiDataCollectionPopupBtnDisable = false;
  saveDataCollection() {
    this.isSaveKpiDataCollectionPopupBtnDisable = true;
    setTimeout(() => {
      this.isSaveKpiDataCollectionPopupBtnDisable = false;
    }, 3000);
    if(this._contractKpiDataCollection.inputAttributeValueType==1)
    {
      this.kpiDataCollectionFrom.controls['_attribute1'].setErrors(null);
      this.kpiDataCollectionFrom.controls['_attribute2'].setErrors(null);
      this.kpiDataCollectionFrom.controls['_attribute3'].setErrors(null);
    }
    else if(this._contractKpiDataCollection.inputAttributeValueType==2){
      this.kpiDataCollectionFrom.controls['_calculatedKPI'].setErrors(null);
      if(this._readOnlyAttribute1=='')
      {
        this.kpiDataCollectionFrom.controls['_attribute1'].setErrors(null);
      }
      if(this._readOnlyAttribute2=='')
      {
        this.kpiDataCollectionFrom.controls['_attribute2'].setErrors(null);
      }
      this.kpiDataCollectionFrom.controls['_attribute3'].setErrors(null);
    }
    else
    {
      this.kpiDataCollectionFrom.controls['_attribute1'].setErrors(null);
      this.kpiDataCollectionFrom.controls['_attribute2'].setErrors(null);
      this.kpiDataCollectionFrom.controls['_attribute3'].setErrors(null);
      this.kpiDataCollectionFrom.controls['_calculatedKPI'].setErrors(null);
    }
    this.dataCollectionSubmitted = true;
    if (this.kpiDataCollectionFrom.valid) {
      if(this._contractKpiDataCollection.inputAttributeValueType==2)
      {
      this.isBtnClicked=true;
      this.CalKPI();
      }
      else{
        this.saveLocalKPIDataCollection();
      }
  }
}

saveLocalKPIDataCollection(){
  const KpiDataCollectionIndex = this._contractKpiDataCollections.findIndex(item => item.id === this.oldSelectedItem.id);
  if (KpiDataCollectionIndex !== -1) {

    if(this._kpiAttributeTypeCode=="INT")
    {
    this.selectedItem.attribute1=this._contractKpiDataCollection.attribute1;
    this.selectedItem.attribute2=this._contractKpiDataCollection.attribute2;
    this.selectedItem.attribute3=this._contractKpiDataCollection.attribute3;
    }
    if(this._kpiAttributeTypeCode=="DTE"){
      this.selectedItem.attribute1=this.datePipe.transform(this._contractKpiDataCollection.attribute1, "dd-MMM-yyyy");
      this.selectedItem.attribute2=this.datePipe.transform(this._contractKpiDataCollection.attribute2, "dd-MMM-yyyy");
      this.selectedItem.attribute3=this.datePipe.transform(this._contractKpiDataCollection.attribute3, "dd-MMM-yyyy");
    }
    // this.selectedItem.calculatedKPI=parseInt( this._contractKpiDataCollection.calculatedKPI ||0)
    this.selectedItem.calculatedKPI=this._contractKpiDataCollection.calculatedKPI==""?null:this._contractKpiDataCollection.calculatedKPI;
    this.selectedItem.health=this._contractKpiDataCollection.health;
    this.selectedItem.kpiAttributeTypeId=this._kpiAttributeTypeId;
    this._contractKpiDataCollections[KpiDataCollectionIndex] = { ...this.selectedItem };

    const indexKpiDataCollection = this.editedKPIDataCollection.findIndex((obj: { id: any; }) => obj.id === this.selectedItem.id);

    if (indexKpiDataCollection !== -1) {
      // If newObj already exists, remove the old object at the index
      this.editedKPIDataCollection.splice(indexKpiDataCollection, 1);
    }
    
    // Push the new object into the array
    this.editedKPIDataCollection.push(this.selectedItem);

    this.selectedItem = null; 
    this.oldSelectedItem=null;
  }
  this.visible = false;
  this.messageService.add({
    key: 'tc',
    severity: 'success',
    summary: 'KPI data collection has been saved',
    detail: '',
});
}



// onNumericInputpopUp(event: Event,_controlname:string) {

//   //Giving error after changing the event from "Input" to "keyup" in Html
//  const inputElement = event.target as HTMLInputElement;
//  const sanitizedValue = inputElement.value.replace(/\D/g, '');
//  inputElement.value = sanitizedValue;
//  this.kpiDataCollectionFrom.controls[_controlname].setValue(sanitizedValue);
//  //this.CalculateKPI();
  
// }


onNumericInputpopUp(event: Event, _controlname: string) {
  const inputElement = event.target as HTMLInputElement;
  let sanitizedValue = inputElement.value.replace(/[^\d.]/g, ''); // Allow only digits and a single dot
  const decimalParts = sanitizedValue.split('.'); // Split value by dot

  // If there are more than 2 decimal parts, keep only the first two parts
  if (decimalParts.length > 1) {
    sanitizedValue = decimalParts[0] + '.' + (decimalParts[1] || '').slice(0, 2); // Keep only up to two decimal places
  }

  inputElement.value = sanitizedValue;
  this.kpiDataCollectionFrom.controls[_controlname].setValue(sanitizedValue);
}

calculatedFieldValue:any;
CalKPI(){
  this._contractKpiDataCollection.attribute1=this._contractKpiDataCollection.attribute1?.toString();
  this._contractKpiDataCollection.attribute2=this._contractKpiDataCollection.attribute2?.toString();

  if(this._contractKpiDataCollection.attribute1!="" && this._contractKpiDataCollection.attribute2!="")
  {
   this.loaderService.showLoader();
   this._contractKPIService.CalculateKPI_New(this._formulaeId,this._contractKpiDataCollection.attribute1,this._contractKpiDataCollection.attribute2,this._contractKpiDataCollection.attribute3,'ContractKPI',this._kpiAttributeTypeCode)
   .then(calculatedKPI => {
    if(calculatedKPI.toString().toLocaleLowerCase()!='infinity')
    {
    if(this.isBtnClicked){
      this.isBtnClicked=false;
      this._contractKpiDataCollection.calculatedKPI=calculatedKPI;
      this.saveLocalKPIDataCollection();
    
    }
    else{
       this._contractKpiDataCollection.calculatedKPI=calculatedKPI;
     }
    }
    else{
      this.isBtnClicked=false;
      this.messageService.add({ key: 'tc', life: 5000, severity: 'error', summary: 'The values you entered for the attributes are resulting in an invalid output. Please re-enter the attribute values.', detail: '' });
    }
       this.loaderService.hideLoader();
   })
   .catch(error => {
     this._contractKpiDataCollection.calculatedKPI=0;
   });
  }
  this.loaderService.hidefinalLoader();
  setTimeout(() => {
   this.finalClear();
 },2000);
 
}

finalClear()
{
 if((this._contractKpiDataCollection.attribute1== "" || this._contractKpiDataCollection.attribute2=="") && this._contractKpiDataCollection.inputAttributeValueType==2)
 {
  this._contractKpiDataCollection.calculatedKPI="";
 }
}
AttributeDateSelection(attributeDate:any,type:number){
  if(this._contractKpiDataCollection.calculatedKPI=="")
  {
    this.dataCollectionSubmitted =false
  }
  if(this._contractKpiDataCollection.attribute1!="" && this._contractKpiDataCollection.attribute2!="" && this._contractKpiDataCollection.attribute1!=null && this._contractKpiDataCollection.attribute2!=null &&this.dataCollectionSubmitted ==false)
   {
    this.loaderService.showLoader();
    this._contractKPIService.CalculateKPI_New(this._formulaeId,this._contractKpiDataCollection.attribute1,this._contractKpiDataCollection.attribute2,this._contractKpiDataCollection.attribute3,'ContractKPI',this._kpiAttributeTypeCode)
    .then(calculatedKPI => {
      this._contractKpiDataCollection.calculatedKPI=calculatedKPI;
        this.loaderService.hideLoader();
    })
    .catch(error => {
      this._contractKpiDataCollection.calculatedKPI=0;
    });
   }
   
}

OnServiceChange(){
  this.PrepareKPISuggestion();
}

PrepareKPISuggestion(){
 
  if(this._contractKpi.kpiDetailId != null && this._contractKpi.kpiDetailId != undefined && this._contractKpi.serviceName != '' && this._contractKpi.serviceName != null && this._contractKpi.serviceName != undefined)
  {
    
    let kpi = this.kpiNameDataSource?.find((aa:any) => aa.id === this._contractKpi.kpiDetailId)?.name;
    this.suggestedKPIName=this.ContractName + '-' + kpi + '-' + this._contractKpi.serviceName;
  
  }
  else if(this._contractKpi.kpiDetailId != null && this._contractKpi.kpiDetailId != undefined && (this._contractKpi.serviceName == '' || this._contractKpi.serviceName == null || this._contractKpi.serviceName == undefined))
  {
    let kpi = this.kpiNameDataSource?.find((aa:any) => aa.id === this._contractKpi.kpiDetailId)?.name;
    this.suggestedKPIName=this.ContractName + '-' + kpi;
  
  }
  else if((this._contractKpi.kpiDetailId == null || this._contractKpi.kpiDetailId == undefined) && this._contractKpi.serviceName != '' && this._contractKpi.serviceName != null && this._contractKpi.serviceName != undefined)
  {
    this.suggestedKPIName=this.ContractName  + '-' + this._contractKpi.serviceName;
  }
  else{
    this.suggestedKPIName=this.ContractName;
  }
  
}

changedContractKPIBasicInfo:any;
    oldContractKPIData:any;
    contractKPIDetailBeforeUpdate:any
    compareModels() {
      if(this._contractKpi.hyperCareStartDate){
        this._contractKpi.hyperCareStartDate= new Date(this._contractKpi.hyperCareStartDate).toISOString();
      }
      if(this._contractKpi.hyperCareEndDate){
        this._contractKpi.hyperCareEndDate= new Date(this._contractKpi.hyperCareEndDate).toISOString();
      }
      this._contractKpi.kpiStartDate= new Date(this._contractKpi.kpiStartDate).toISOString();
      this._contractKpi.kpiEndDate= new Date(this._contractKpi.kpiEndDate).toLocaleDateString();//toISOString is making one day less

      this.contractKPIDetailBeforeUpdate=JSON.parse(this.contractKPIDetailBeforeUpdate);
      this.contractKPIDetailBeforeUpdate.contractDetailId=this._contractKpi.contractDetailId;
      this.contractKPIDetailBeforeUpdate=JSON.stringify(this.contractKPIDetailBeforeUpdate);
      this.changedContractKPIBasicInfo = this.auditLogService.compareModels(JSON.parse(this.contractKPIDetailBeforeUpdate),this._contractKpi,this.auditEntities.ContractKPIs,this.auditIdentifierDetails.ContractKPIs,this.auditOperations.Update,this.auditModule.GovernanceService);
    }


    CalculationFormulae(){
      //this._readOnlyFormulaName
      let data=this._readOnlyFormulaName;
      data=data.replace('Attribute 1',this._readOnlyAttribute1);
      data=data.replace('Attribute 2',this._readOnlyAttribute2);
      data=data.replace('Attribute 3',this._readOnlyAttribute3);
      this._readOnlyCalculation=data;
     }
    

    currencies: any='USD';
  getFormattedValue(value: number, currencyName: string): string {
  let formattedValue = this.currencyService.getFormattedValue(value, currencyName);
 return this.handleCurruncy(formattedValue);
 }
 private handleCurruncy(formattedValue: string): string {
   return formattedValue.replace(/^[^\d]+/, '');
}
selectedKpiReportData: any;
  findingsCount : any;
displayMitigationListPopup: boolean = false;
displayViewMitigationPopup: boolean = false;
position: string = 'center';

oldMitigationActionsData: any[] = [];
oldkpidata:any;
   showFindingList(Kpidata: any) {
    this.selectedKpiReportData = Kpidata;
    this.oldkpidata=Kpidata.contractKpiMitigationActionsList;
      this.addMitigationActionsData = Kpidata.contractKpiMitigationActionsList ? Kpidata.contractKpiMitigationActionsList.filter((item: { isDeleted: any; }) => !item.isDeleted)
      .map((item: { targetDate: string; TargetDate: string; closureDate: string; ClosureDate: string; }) => {
      const formattedTargetDate = item.targetDate ? this.convertDateFormat(item.targetDate) : item.TargetDate ? this.convertDateFormat(item.TargetDate) : null;
      const formattedClosureDate = item.closureDate ? this.convertDateFormat(item.closureDate) : item.ClosureDate ? this.convertDateFormat(item.ClosureDate) : null;
  
      return {
        ...item,
        TargetDate: formattedTargetDate ? new Date(formattedTargetDate) : null,
        ClosureDate: formattedClosureDate ? new Date(formattedClosureDate) : null,
      };
    }) : [];
  
    // Update other properties as needed
    this.addMitigationActionsData.forEach(item => {
      item.Id = item.id || item.Id;
      item.KpiId = item.kpiid || item.KpiId;
      item.DataCollectionId = item.dataCollectionId || item.DataCollectionId;
      item.Action = item.action || item.Action;
      item.ActionOwner = item.actionOwnerId || item.ActionOwner;
      item.StatusMappingId = item.statusMappingId || item.StatusMappingId;
      item.TargetDate= item.targetDate!=null ? new Date(item.targetDate) : null  || item.TargetDate!=null ? new Date(item.TargetDate) : null ;
      item.ClosureDate= item.closureDate!=null ? new Date(item.closureDate) : null  || item.ClosureDate !=null ? new Date(item.ClosureDate) : null ;
    });
    this.oldMitigationActionsData = JSON.parse(JSON.stringify(this.addMitigationActionsData));
    this.visableMitigations = this.addMitigationActionsData.filter(action => !action.isDeleted);
    this.selectedKpiReportData.findingsCount = this.addMitigationActionsData.length;
   this.displayMitigationListPopup = true;
   this.addMitigationSaved = false;
} 

  cancelMitigationDialog() {
    if (JSON.stringify(this.oldMitigationActionsData) !== JSON.stringify(this.addMitigationActionsData)) {
      this.confirmationService.confirm({
        message: 'Changes will not be saved, Do you want to Continue?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: 'Changes not saved',
            detail: '',
          });
          this.displayMitigationListPopup = false;
        },
        reject: () => {
        },
      });
    } else {
      this.displayMitigationListPopup = false;
    }
    }
  addMitigationActionsData: any[] = [];
  CreateMitigationActions() { //Click on Add Action button new row will be generated on top of the grid
    this.mitigationForm.reset();
    this.addMitigationActionsData.unshift({
      Id: 0,
      Action: '',
      ActionOwner: "",
      TargetDate: null,
      StatusMappingId: '',
      ClosureDate: null,
      IsDeleted: false
    });
    this.visableMitigations = this.addMitigationActionsData.filter(action => !action.isDeleted);
  }
  visableMitigations:any;
  deleteAction(currentActionIndex: number) {  
    this.confirmationService.confirm({
         header: 'Confirm',
         message: 'Are you sure you want to delete this action?',
         accept: () => {
          this.visableMitigations.splice(currentActionIndex,1);
          this.addMitigationActionsData=this.visableMitigations;
          
         this.messageService.add({ severity: 'success', summary: 'Mitigation Action Deleted', detail: '', });
         }
       });
     }

     supplierContactsDataSource : SupplierContact[]=[];
     sponsorContactsDataSource : SponsorContact[]=[];
     GetAllsupplierContacsById(supplierId:number=0) {
   
      this.supplierService.GetSupplierById(supplierId).subscribe(res => {
          this.supplierContactsDataSource=res.data.supplierContacts;
      });
    
    }
    GetAllSponsorContactsById(sponsorId:number=0) {
      this.masterDataService.getSponserData(sponsorId).subscribe(res=>{
        this.sponsorContactsDataSource= res.data.sponserContacts;
      });
    }
    combinedContacts: any[] = [];
    CombinedContacts(supplierId: number = 0, sponsorId: number = 0) {
      this.supplierService.GetSupplierById(supplierId).subscribe(supplierRes => {
          this.supplierContactsDataSource = supplierRes.data.supplierContacts;
          this.masterDataService.getSponserData(sponsorId).subscribe(sponsorRes => {
            this.sponsorContactsDataSource = sponsorRes.data.sponserContacts.map((contact: { id: any; name: any; countryCode: any; designation: any; phoneNumber: any; email: any; sponserId: any; createdBy: any; createdDate: any; updatedBy: any; updatedDate: any; isDeleted: any; }) => {
              return {
                  id: contact.id,
                  contactName: contact.name, 
                  countryCode: contact.countryCode,
                  designation: contact.designation,
                  phoneNumber: contact.phoneNumber,
                  email: contact.email,
                  supplierProfileId: contact.sponserId, 
                  createdBy: contact.createdBy,
                  createdDate: contact.createdDate,
                  updatedBy: contact.updatedBy,
                  updatedDate: contact.updatedDate,
                  isDeleted: contact.isDeleted
              };
          });
              this.combinedContacts = [...this.supplierContactsDataSource, ...this.sponsorContactsDataSource];
          });
      });
  }
  actionStatus:any;
  GetMitigationStatus(enumId: number, type: string) { //Get Meeting Status's from code list for the Meetings
    this.masterDataService.getCodeListData(enumId, type).subscribe(
      res => {      
        if (res.data.length > 0) {
          this.actionStatus = res.data;
        }
      }
    );
  };
  _addActionsFieldsMandatory: boolean = false;
    onSaveMitigationActions() {
    this.addMitigationSaved = true;
    this._addActionsFieldsMandatory = this.addMitigationActionsData.some((action) => {
      const status = this.actionStatus.find((a: { id: any; }) => a.id === action.StatusMappingId);
      if (
        this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(action.Action) || 
        this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(action.StatusMappingId) || 
        (status && status.code === "CLD" && this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(action.ClosureDate))
      ) {
        return true;
      }
      return false;
    });

    if (this.validatingFields()) {
    this.selectedKpiReportData.contractKpiMitigationActionsList = this.addMitigationActionsData.map(action => ({
      ...action,
      StatusMappingId: action.StatusMappingId,
      ActionOwner: action.ActionOwner === '' ? 0 : action.ActionOwner,
      TargetDate: action.TargetDate || '', 
      ClosureDate: action.ClosureDate || '', 
    }));

    this.selectedKpiReportData.findingsCount = this.addMitigationActionsData.filter(action => !action.isDeleted).length; 
    this.displayMitigationListPopup = false;
  } else {
        Object.values(this.mitigationForm.controls).forEach(control => {
      control.markAsTouched();
    });
  }
  }
  validatingFields(): boolean {
    for (const actionData of this.addMitigationActionsData) {
      const status = this.actionStatus.find((s: { id: any; }) => s.id === actionData.StatusMappingId);
      if (!actionData.Action || actionData.Action.trim() === '') {
        return false;
      }
      if (!actionData.StatusMappingId) {
        return false;
      }
      if (status && status.code === "CLD")
        {
        if (!actionData.ClosureDate) {
          return false;
        }
      }
    }
    return true;
  }
  convertDateFormat(inputDateTime: string) {
    const date = new Date(inputDateTime);
    const formattedDate = this.datePipe.transform(date, this._dateformart.AuditFormat);
    return formattedDate;
  }
  oldKpiData: any;
  newKpiData: any;
  mitigationDataCollection: any[] = [];
  newMitigation:any;
 async compareMitigationActions() {
    this.oldKpiData=JSON.parse(this.contractKPIDetailBeforeUpdate);
    this.newKpiData=this._contractKpi;
    const oldDataCollectionList = this.oldKpiData.dataCollectionList;
    const newDataCollectionList = this.newKpiData?.kpiDataCollection;
    oldDataCollectionList.forEach((oldData: { contractKpiMitigationActionsList: any; dataCollectionDate: any; }, index: string | number) => {
     const newData = newDataCollectionList ? newDataCollectionList[index] : null;
      if (oldData && newData) {
        const oldMitigations = oldData.contractKpiMitigationActionsList;
        const newMitigations = newData.contractKpiMitigationActionsList;
          if(oldMitigations.length==0 && newMitigations.length!=0 )
          {
            newMitigations.forEach((newitigation: any) => {
              this.mitigationDataCollection.push({
                'kpiName':this.newKpiData.kpiName,
                'engagementName':this.EngagementName,
                'mitigationactionstatus':this.actionStatus?.find((aa:any) => aa.id === newitigation?.statusMappingId)?.name,
                'engagementId':0,
                'contractTitle':this.ContractName,
                 "Datafor": newData.dataCollectionFrequencyValue,
                 "kpiValue": newData.calculatedKPI === null ? '--':newData.calculatedKPI+''+this._readOnlySybmolSteadyStateThreshold,
                 "Actionfieldvalue": newitigation.action,
                 'contractId':this.ContractId,
                 'id':newitigation.id,
                 'ownerEmail':this.getEmailById(newitigation.actionOwnerId),
               });

            });
          }
          else
        {
         const filterNewMitigations = newMitigations?.filter((t: { id: number; })=>t.id==0);
         filterNewMitigations.forEach((newitigation: any) => {
          this.mitigationDataCollection.push({
            'kpiName':this.newKpiData.kpiName,
            'engagementName':this.EngagementName,
            'mitigationactionstatus':this.actionStatus?.find((aa:any) => aa.id === newitigation?.statusMappingId)?.name,
            'engagementId':0,
            'contractTitle':this.ContractName,
             "Datafor": newData.dataCollectionFrequencyValue,
             "kpiValue": newData.calculatedKPI === null ? '--':newData.calculatedKPI+''+this._readOnlySybmolSteadyStateThreshold,
             "Actionfieldvalue": newitigation.action,
             'contractId':this.ContractId,
             'id':newitigation.id,
             'ownerEmail':this.getEmailById(newitigation.actionOwnerId),
           });
        });

        const newMitigationsMap = new Map(newMitigations.map((mitigation: any) => [mitigation.id, mitigation]));
        oldMitigations.forEach((oldMitigation: any) => {
          this.newMitigation = newMitigationsMap.get(oldMitigation.id);
           if ( this.newMitigation) {
           const _oldStatus = this.actionStatus?.find((aa:any) => aa.id === oldMitigation.statusMappingId)?.code;
           const _newStatus = this.actionStatus?.find((aa:any) => aa.id === this.newMitigation?.statusMappingId)?.code;
             if (_oldStatus=='OPN' && _newStatus=='CLD') {
              this.mitigationDataCollection.push({
                'kpiName':this.newKpiData.kpiName,
                'engagementName':this.EngagementName,
                'mitigationactionstatus':this.actionStatus?.find((aa:any) => aa.id === this.newMitigation?.statusMappingId)?.name,
                'engagementId':0,
                'contractTitle':this.ContractName,
                 "Datafor": newData.dataCollectionFrequencyValue,
                 "kpiValue": newData.calculatedKPI === null ? '--':newData.calculatedKPI+''+this._readOnlySybmolSteadyStateThreshold,
                 "Actionfieldvalue": this.newMitigation.action,
                 'contractId':this.ContractId,
                 'id':this.newMitigation.id,
                 'ownerEmail':this.getEmailById(this.newMitigation.actionOwnerId),
               });
             }
           };
         });
        }
       }
     });
     if(this.mitigationDataCollection.length>0)
     {
     await this.notificationService.KpiMitigationNotification(this.kpiId,this.mitigationDataCollection,0,this.SponsorId,this.SupplierId)
  }
}




  
  
  isClosedStatus(statusMappingId: number): boolean {
    const status = this.actionStatus.find((s: { id: number; }) => s.id === statusMappingId);
    return status ? status.code === "CLD" : false;
  }

  updateValidators(controlName: string) {
    const control = this.myContrctKPIForm.get(controlName);
    if (control) {
      if (control.value === 'NA') {
        control.clearValidators();
        control.setValidators([Validators.required, this.noOnlySpacesValidator, Validators.pattern(/^[^&%]*$/)]);
      } else {
        control.setValidators([Validators.required, Validators.minLength(3), this.noOnlySpacesValidator, Validators.pattern(/^[^&%]*$/)]);
      }
      control.updateValueAndValidity();
    }
  }

  navigateTo(index: number): void {
    if (index >= 0 && index < this.listOfKpis.length) {
      this.ContractKpiId=this.listOfKpis[index].id;
      this.kpiId=this.ContractKpiId;
      this.GetContractKpiById(this.ContractKpiId)
      this.currentIndex=index;
      this.formOldContractKpi();
      this.DisableNxtPreButton();
    }
  }
  nextRecord(): void {
    this.navigateTo(this.currentIndex + 1);
  }
  previousRecord(): void {
    this.navigateTo(this.currentIndex - 1);
  }
  getCurrentPositionText(): string {
    return `${this.currentIndex + 1} of ${this.listOfKpis.length}`;
  }
  pageChangeConfirmation(buttonType:number):void{
    if((this._oldContractKpi==JSON.stringify(this._contractKpi)))
    {
      buttonType==1?this.nextRecord():this.previousRecord();
    }
    else{
      this.confirmationService.confirm({
        message: 'Changes will not be saved, Do you want to Continue?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: 'Changes not saved',
            detail: '',
        });
         buttonType==1?this.nextRecord():this.previousRecord();
        },
        reject: () => {
      
        },
      });
    }
  }
  buttonDisabled:boolean = false;
  DisableNxtPreButton() 
  {
    this.buttonDisabled = true;
    setTimeout(() => {
      this.buttonDisabled = false;
    },2000); 
  }
  getEmailById(id: number): string | undefined {
    const contact = this.combinedContacts.find(c => c.id === id);
    if(contact!=undefined)
    return contact?.email;
    else
    return "";
  }
}
