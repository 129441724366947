import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, NgModel, ValidationErrors, Validators } from '@angular/forms';
import { ConfirmationService, MessageService,  MenuItem } from 'primeng/api';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { Dateformat,breadcrumnsCodes, AuditIdentifier, AuditLogEntities, AuditOperations, AuditLogModule, FrequencyValues, CodeListStatusTypes, CodeList } from 'src/app/shared/constants/global.constants';
import { LoaderService } from 'src/app/Loader/loader.service';
import { Observable} from 'rxjs';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { CodeListValues, EngagementKpi, EngagementKpiDataCollection, EngagementKPIHyperCareThreshold, EngagementKPISteadyStateThreshold,   MasterKpiData } from '../../Model/engagementKpi';
import { EngagementKpiService } from '../../Services/engagement-kpi.service';
import { ContractKPIService } from '../../../Contract/Services/ContractKpi.Service';
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/shared/Notifications/notification.service';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { CommonService } from 'src/app/shared/Service/common.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { SponsorContact, SupplierContact } from 'src/app/MasterData/Services/supplier-profile';
import { ChecknulloremptyComponent } from 'src/app/shared/checknullorempty/checknullorempty.component';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';
import { KPIThreshold } from '../../../Contract/Model/contractKpi';

@Component({
  selector: 'app-eng-edit-kpi',
  templateUrl: './eng-edit-kpi.component.html',
  styleUrls: ['./eng-edit-kpi.component.css']
})
export class EngEditKpiComponent {
  isSaveBtnDisable = false;
  ingredient!: string;
  showDialog() {
    this.visible = true;
}
@Input() engagementId: any;
@Input() kpiId: any;
@Output() cancelKpitButtonClick = new EventEmitter<void>();
@Output() saveKpiButtonClick = new EventEmitter<void>();
@Output() viewKpiButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closureDateInput') closureDateInput!: NgModel;
  visible: boolean = false; 
  editlist: any[] = [] ;
  KPIValue: any[] = [] ;
  date: Date | undefined;
  executeCanExist: boolean = true;
  breadcrumbs: MenuItem[]=[];
  _enumBreadcrumb :any;
  _dateformart :any;
   isLoading=false;
  kpiNameDataSource: CodeListValues[] | undefined;
  kpiReportingFrequencyDataSource: CodeListValues[] | undefined;
  kpiDataCollectionFrequencyDataSource: CodeListValues[] | undefined;
  statusDataSource: CodeListValues[] | undefined;
  hyperCareThresholdDataSource: CodeListValues[] | undefined;
  steadyStateThresholdDataSource: CodeListValues[] | undefined;
  masterKpiDataSource: MasterKpiData[] | undefined;
  _engagementKpi=new EngagementKpi();
  _kpiSteadyStateThreshold= new EngagementKPISteadyStateThreshold();
  _kpiSteadyStateThresholds: EngagementKPISteadyStateThreshold[]=[];
  _kpiHyperCareThreshold= new EngagementKPIHyperCareThreshold();
  _kpiHyperCareThresholds: EngagementKPIHyperCareThreshold[]=[];
  _dynamicKpiHyperCareThreshold= new EngagementKPIHyperCareThreshold();
  _dynamicKpiHyperCareThresholds: EngagementKPIHyperCareThreshold[]=[];
  _dynamicKpiSteadyStateThreshold= new EngagementKPISteadyStateThreshold();
  _dynamicKpiSteadyStateThresholds: EngagementKPISteadyStateThreshold[]=[];
  _readOnlyUnitofMeasure:string="";
  _readOnlyCategory:string="";
  _readOnlyComplianceIndicator:string="";
  _readOnlyCalculation:string="";
  _readOnlyAttribute1:string="";
  _readOnlyAttribute2:string="";
  _readOnlyAttribute3:string="";
  _readOnlyFormulaName:string="";
  _formulaeId:number=0;
  _kpiAttributeTypeCode:string="";
  _kpiAttributeTypeId:number=0;
  _readOnlySybmolHyperCareThreshold:string="";
  _readOnlySybmolSteadyStateThreshold:string="";
  _isNotApplicable:boolean=false;
  _unitCode:string="";
   submitted = false;
    KpiMinEndDate:any;
    KpiMaxStartDate:any;
    hyperCareMinEndDate:any;
    hyperCareMaxStartDate:any;
    myEngagementKPIForm: FormGroup;
    kpiDataCollectionFrom: FormGroup;
    EngagementId:any;
    EngKpiId:any;
    EngagementName:any;
    _oldEngagementKpi:any;
    _iskpiEdit:any;
    _engagementKpiDataCollection= new EngagementKpiDataCollection();
    _engagementKpiDataCollections: EngagementKpiDataCollection[]=[];
    _oldengagementKpiDataCollections: EngagementKpiDataCollection[]=[];
    selectedItem: any;
    oldSelectedItem: any;
    dataCollectionSubmitted=false;
    _overAllAverageKPI?:string="";
    _overallAverageHealth?:string="";
    suggestedKPIName:string="";
    kpiCreatedUserName:string="";
    kpiUpdatedUserName:string="";
    mitigationForm: FormGroup;

    editedKPIDataCollection:any=[];//msg
    frequencyData:any;
    @Input() SupplierId: any;
    @Input() SponsorId: any;
    listOfKpis:any;
    currentIndex: number = -1; 
    constructor(private privilegeService: PrivilegeService,private router: Router, private fb: FormBuilder, private confirmationService: ConfirmationService,
       private messageService: MessageService, private loaderService: LoaderService,
        private breadcrumbService: BreadcrumbService,private masterDataService: SponserService,
        private service: EngagementKpiService, private datepipe: DatePipe,
        private activeRouter: ActivatedRoute, private _contractKPIService:ContractKPIService,private datePipe: DatePipe, private notificationService:NotificationService,
        private auditLogService: AuditLogService,private commonService:CommonService, private engagementService: EngagementService,private currencyService: CurrencyService,private supplierService: MasterSupplierService, private checkNullOrEmptyValuesService: ChecknulloremptyComponent,private tabAndStateService: TabAndStateService){
      this.myEngagementKPIForm = this.fb.group({
        _KPIName:[{ value: '', disabled: true }],
        _kpiDataCollectionFrequency:[{ value: '', disabled: true }],
        _kpiReportingFrequency:['', [Validators.required]],
        //_kpiStartDate:[{ value: '', disabled: true }],
        //_kpiEndDate:[{ value: '', disabled: true }],
        _status:['', [Validators.required,this.validateStatus]],
        _unitOfMeasure: [{ value: '', disabled: true }],	
  
        _hyperCareThreshold: [null, this._isNotApplicable ? null : Validators.required],
        // _hyperCareThresholdValue:['',[this._isNotApplicable ? null : Validators.required,Validators.minLength(1),Validators.maxLength(3),Validators.pattern(/^[0-9]*$/)]],
        _hyperCareThresholdValue:['',[this._isNotApplicable ? null : Validators.required,Validators.minLength(1)]],
        _hyperCareThresholdsymbol:[{ value: '', disabled: true }],
        _hyperCareStartDate:[{ value: '', disabled: true }],
        _hyperCareEndDate:[{ value: '', disabled: true }],
  
        _dyhyperCareThreshold: [''],
        _isNotApplicable: [''],
        _steadyStateThreshold:['', [Validators.required]],
        _dysteadyStateThreshold:[''],
        _dyhyperCareThresholdValue:[''],
        _dyhyperCareThresholdsymbol:[{ value: '', disabled: true }],	
  
        // _steadyStateThresoldValue:['',[Validators.required,Validators.minLength(1),Validators.maxLength(3),Validators.pattern(/^[0-9]*$/)]],
        _steadyStateThresoldValue:['',[Validators.required,Validators.minLength(1)]],
        _steadyStateThresoldSymbol:[{ value: '', disabled: true }],	
        _dysteadyStateThresoldValue:[''],
        _dysteadyStateThresoldSymbol:[{ value: '', disabled: true }],	
        _category:[{ value: '', disabled: true }],	
        _complianceIndicator:[{ value: '', disabled: true }],	
        _calculation:[{ value: '', disabled: true }],	

        _attribute1:['', [Validators.required,Validators.minLength(3),this.noOnlySpacesValidator,Validators.pattern(/^[^&%]*$/)]],
      _attribute2:['', [Validators.required,Validators.minLength(3),this.noOnlySpacesValidator,Validators.pattern(/^[^&%]*$/)]],
        _attribute3:[{ value: ''}],	

        _kpiDescription:['',[Validators.maxLength(300)]],	
        _serviceName:['', [Validators.required,Validators.maxLength(200),this.noOnlySpacesValidator,Validators.pattern(/^[^&%]*$/)]],
        _kpiName:['', [Validators.required,Validators.minLength(3),Validators.maxLength(400),this.noOnlySpacesValidator,Validators.pattern(/^[^&%]*$/)]],
        _frequencyValueforStartDate:[{ value: '', disabled: true }],//:['', [Validators.required]],
        _kpiStartDateYear:[{ value: '', disabled: true }],//:['', [Validators.required]],
        _frequencyValueforEndDate:['', [Validators.required]],
        _kpiEndDateYear:['', [Validators.required,Validators.minLength(3)]],
      
      });
      //data collection from
      this.kpiDataCollectionFrom = this.fb.group({
        _calculatedKPI: ['',[Validators.required]],
        _remarks: ['',[Validators.required]],
        _attribute1: ['',[Validators.required]],
        _attribute2: ['',[Validators.required]],
        _attribute3:[''],
        _health:[''],
      });
      this.mitigationForm = this.fb.group({
       Action: ['', Validators.required],
        Status:['', Validators.required]
      });
    }; 
    validateStatus(control: FormControl) {
      const status = control.value;
      if (status === undefined || status === 0) {
          return { required: true }; // Validation failed
      }
      return null; // Validation passed
  }
    noOnlySpacesValidator(control: AbstractControl): ValidationErrors | null {
      if (control.value && control.value.trim().length === 0) {
        return { onlySpaces: true };
      }
      return null;
    }
    auditIdentifierDetails:any;
    auditEntities:any;
    auditOperations:any;
    auditModule:any;
    codeListValues:any;
    codeListStatusTypes:any;
    addMitigationSaved = false;
    ngOnInit() {
      this.EngKpiId=this.kpiId;
      this.EngagementId=this.engagementId;
      this.KpiMinEndDate=null;
      this.KpiMaxStartDate=null;
      this.hyperCareMinEndDate=null;
      this.hyperCareMaxStartDate=null;
      this._enumBreadcrumb=breadcrumnsCodes;
      this._dateformart=Dateformat;
      this.auditIdentifierDetails=AuditIdentifier; 
      this.auditEntities=AuditLogEntities;
      this.auditOperations=AuditOperations;
      this.auditModule=AuditLogModule;

      this.FindAllKpiNames(7);
      this.FindAllKpiReportingFrequencies(9) ;
      this.FindAllStatus(3);
      this.FindAllHyperCareThresholds(10);
      this.FindAllMasterKpis(11);
      this.FindEngagementDataById(this.EngagementId);
      this.formOldEngagementKpi();
      this.FindKpiById(this.EngKpiId);
      this.codeListValues=CodeList;
      this.codeListStatusTypes=CodeListStatusTypes
      this.GetMitigationStatus(this.codeListValues.Tbl_Status, this.codeListStatusTypes.MASTS);
      this.listOfKpis=this.tabAndStateService.getKpiData();
       this.currentIndex = this.listOfKpis.findIndex((a: { id: any; }) => a.id ==this.EngKpiId);

  }
  
  
  FindAllKpiNames(type:number) {
    this.masterDataService.getCodeListData(type,'SupplierKPI').subscribe(
      res=>{
        this.kpiNameDataSource=res.data;
      }
    );
  };
  
  FindAllKpiReportingFrequencies(type:number) {
    this.masterDataService.getCodeListData(type,'').subscribe(
      res=>{
        this.kpiReportingFrequencyDataSource=res.data;
        
      }
    );
  };

  isFrequencyAnnual=false;
  FindAllkpiDataCollectionFrequenies(type:number) {
    this.masterDataService.getCodeListData(type,'').subscribe(
      res=>{
        this.kpiDataCollectionFrequencyDataSource=res.data;

        let frequency=this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==this._engagementKpi.kpiDataCollectionFrequencyId)[0].name;
        const kpiStart=this.commonService.getFrequencyFromDate(this._engagementKpi.kpiStartDate,frequency);
        const kpiEnd=this.commonService.getFrequencyFromDate(this._engagementKpi.kpiEndDate,frequency);
        
        //Get Frequency Data Here
        if(frequency=="Monthly")
          this.frequencyData=JSON.parse(FrequencyValues.Monthly);
        else if(frequency=="Quarterly")
          this.frequencyData=JSON.parse(FrequencyValues.Quarterly);
        else if(frequency=="Half-Year")
          this.frequencyData=JSON.parse(FrequencyValues.HalfYearly);
        else if(frequency=="Annual")
          this.frequencyData=JSON.parse(FrequencyValues.Annual);

          if(frequency=="Annual"){
            this.isFrequencyAnnual=true;
          }
          else{
            this.isFrequencyAnnual=false;
          }

          this.SetFrequencyValueforDataCollection(this._engagementKpi.kpiDataCollectionFrequencyId);

          this.BindFrequencyDates(kpiStart,kpiEnd,frequency,this.frequencyData);
      }
    );
  };
  FindAllStatus(type:number) {
    this.masterDataService.getCodeListData(type,'KPI Status').subscribe(
      res=>{
        this.statusDataSource=res.data;
      }
    );
  };
  FindAllHyperCareThresholds(type:number) {
    this.masterDataService.getCodeListData(type,'').subscribe(
      res=>{
        this.hyperCareThresholdDataSource=res.data;
        this.steadyStateThresholdDataSource=res.data;
      }
    );
  };
  FindAllMasterKpis(type:number) {
    this.masterDataService.GetAllSupplierMasterData(type).subscribe(
      res=>{
        this.masterKpiDataSource=res.data;
      }
    );
  };

  OnChangeKPIName(kpiDetailId: number) {
    
  
    const filteredData = this.masterKpiDataSource?.find(t => t.kpiDetailId === kpiDetailId);
  
    if (filteredData) {
      this._readOnlyUnitofMeasure = filteredData.unitOfMeasure;
      this._readOnlyCategory = filteredData.category;
      this._readOnlyComplianceIndicator = filteredData.complianceIndicator;
      this._unitCode = filteredData.unitOfMeasureCode;
      this._formulaeId = filteredData.formulaeId;
      this._readOnlyFormulaName = filteredData.formulaName;
      this._kpiAttributeTypeCode = filteredData.kpiAttributeTypeCode;
      this._kpiAttributeTypeId = filteredData.kpiAttributeTypeId;
  
      // Conditional assignment based on _isNotApplicable
      this._readOnlySybmolHyperCareThreshold = this._isNotApplicable ? "" : this._unitCode;
      this._readOnlySybmolSteadyStateThreshold = this._unitCode;
    }
  
    this.PrepareKPINameSuggestion();
  }
  

  
  
  onUpdateEngagementKpi()
  {
    this.isSaveBtnDisable = true;
    if(this._isNotApplicable)
    {
      this.myEngagementKPIForm.controls['_hyperCareStartDate'].setErrors(null);
      this.myEngagementKPIForm.controls['_hyperCareEndDate'].setErrors(null);
      this.myEngagementKPIForm.controls['_hyperCareThresholdValue'].setErrors(null);
      this.myEngagementKPIForm.controls['_hyperCareThreshold'].setErrors(null);
      
    }
    setTimeout(() => {
      this.isSaveBtnDisable = false;
    }, 3000);

    if(this.DataCollectionFrequency == "Annual"){
      this.myEngagementKPIForm.controls['_frequencyValueforStartDate'].setErrors(null);
      this.myEngagementKPIForm.controls['_frequencyValueforEndDate'].setErrors(null);
    }
     //validate dynamic fileds
 if(this._dynamicKpiHyperCareThresholds?.length!=0)
 {
  this.myEngagementKPIForm.controls['_dyhyperCareThresholdValue'].setErrors(this._dynamicKpiHyperCareThreshold.percentageValue=="" ? {required: true} :null);
  this.myEngagementKPIForm.controls['_dyhyperCareThreshold'].setErrors(this._dynamicKpiHyperCareThreshold.mathematicalSymbolId==0 ? {required: true} :null);
 }
 if(this._dynamicKpiSteadyStateThresholds?.length!=0)
 {
  this.myEngagementKPIForm.controls['_dysteadyStateThresoldValue'].setErrors(this._dynamicKpiSteadyStateThreshold.percentageValue=="" ? {required: true} :null);
  this.myEngagementKPIForm.controls['_dysteadyStateThreshold'].setErrors(this._dynamicKpiSteadyStateThreshold.mathematicalSymbolId==0 ? {required: true} :null);
 }
    this.submitted = true;
   
    this.updateValidators('_attribute1');
    this.updateValidators('_attribute2');
    if (this.myEngagementKPIForm.valid) {
      this.executeCanExist = false;
      this.loaderService.showLoader();
    
      this._engagementKpiDataCollections.forEach(collection => {
        if (collection.engagementKpiMitigationActionsList) {
            collection.engagementKpiMitigationActionsList = collection.engagementKpiMitigationActionsList
                .map(action => this.updateMitigations(action)); 
        }
    });
      
      if(!this._isNotApplicable)
      {
        this._kpiHyperCareThresholds.push(this._kpiHyperCareThreshold);
        if(this._dynamicKpiHyperCareThreshold.mathematicalSymbolId!=0)
        this._kpiHyperCareThresholds.push(this._dynamicKpiHyperCareThreshold)
      }
      this._kpiSteadyStateThresholds.push(this._kpiSteadyStateThreshold);
      if(this._dynamicKpiSteadyStateThreshold.mathematicalSymbolId!=0)
      this._kpiSteadyStateThresholds.push(this._dynamicKpiSteadyStateThreshold);
      this._engagementKpi.EngagementKPIHyperCareThreshold=this._kpiHyperCareThresholds;
      this._engagementKpi.EngagementKPISteadyStateThreshold=this._kpiSteadyStateThresholds;
      this._engagementKpi.engagementId=this.EngagementId;

      this._engagementKpi.kpiReportingFrequencyCode=this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==this._engagementKpi.kpiDataCollectionFrequencyId)[0].code;
     

      this._engagementKpi.updatedBy=this.privilegeService.getLoginUserId();
      this._engagementKpi.EngagementDataCollectionList=this._engagementKpiDataCollections;
      if(this._engagementKpi.hyperCareEndDate != undefined && this._engagementKpi.hyperCareEndDate != null)
    {
      this._engagementKpi.hyperCareEndDate =  this.datePipe.transform(this._engagementKpi.hyperCareEndDate, "MM/dd/yyyy");
    }
    if(this._engagementKpi.hyperCareStartDate != undefined && this._engagementKpi.hyperCareStartDate != null)
    {
      this._engagementKpi.hyperCareStartDate = this.datePipe.transform(this._engagementKpi.hyperCareStartDate, "MM/dd/yyyy");
    }  
    this._engagementKpi.attribute1=this._readOnlyAttribute1;
    this._engagementKpi.attribute2=this._readOnlyAttribute2;
    this._engagementKpi.attribute3=this._readOnlyAttribute3;
    this._engagementKpi.calculation=this._readOnlyCalculation;

    let frequency=this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==this._engagementKpi.kpiDataCollectionFrequencyId)[0].name;
    
    this._engagementKpi.kpiStartDate=this.commonService.getCompleteDate(frequency,this._engagementKpi.frequencyValueforStartDate,this._engagementKpi.kpiStartDateYear);
    this._engagementKpi.kpiEndDate=this.commonService.getCompleteDate(frequency,this._engagementKpi.frequencyValueforEndDate,this._engagementKpi.kpiEndDateYear);
    
   //Check Start date should always greater than End Date
    if(this._engagementKpi.kpiStartDate>this._engagementKpi.kpiEndDate)
    {
      this.messageService.add({  key: 'tc',life: 4000,severity: 'warn', summary: 'KPI Tracking End should be greater than KPI Tracking Start', detail: '' });  
      this.loaderService.hideLoader();    
    }
    else{

      //Check End Date should always greater than previous End Date
      if(this._engagementKpi.kpiEndDate<new Date(this.previousEndDate)){
        this.messageService.add({  key: 'tc',life: 4000, severity: 'warn', summary: 'KPI Tracking End can only be extended and cannot be shortened', detail: '' });  
        this.loaderService.hideLoader();  
      }
      else{

      this._engagementKpi.kpiEndDate = this.datePipe.transform(this._engagementKpi.kpiEndDate, "MM/dd/yyyy");
      this._engagementKpi.kpiStartDate = this.datePipe.transform(this._engagementKpi.kpiStartDate, "MM/dd/yyyy");
        this.service.UpdateEngagementKpi(this._engagementKpi).subscribe(async res => {
       if(res.status)
       {
        this.compareMitigations();
        if(this.editedKPIDataCollection?.length>0){
                  let kpiData ={
                   'kpiName':this._engagementKpi.kpiName,
                   'engagementName':this.EngagementName,
                   'contractTitle':'',
                   'engagementId':this.engagementId,
                   'contractId':0,
                   'kpiId':this.EngKpiId,
                   'kpiDataCollectionEdited':this.editedKPIDataCollection,
                   'kpiDataCollection':this._oldengagementKpiDataCollections,
                   'attribute1':this._engagementKpi.attribute1,
                   'attribute2':this._engagementKpi.attribute2,
                   'attribute3':this._engagementKpi.attribute3,
                  }
                  //below line commented for resove everloading issue
          await this.notificationService.KPIThresholdNotification(0,kpiData,this.EngKpiId,this.SponsorId,this.SupplierId)// call the KPI Threshold send notification service metod param1 - contract KPI ID, param2 - required KPI data, param3 - supplier KPI Id
        }

         

        this.messageService.add({ key: 'tc', severity: 'success', summary: 'Changes have been saved', detail: '' });
        this.compareModels();
          this.loaderService.hideLoader();
          const _dataResult = {
            engagementId:this.engagementId,
            kpiId:this.kpiId,
          };
        this.viewKpiButtonClick.emit(_dataResult);
       }
     });
    }
    }
    }
    else{
      this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });  
    }
  }
  updateMitigations(action: any): any {
    return {
      id: action.Id ?? action.id ?? null,
      action: action.Action ?? action.action ?? '',
      actionOwnerId: action.ActionOwner ?? action.actionOwnerId ?? null,
      targetDate: this.datePipe.transform(action.TargetDate ?? action.targetDate, 'MM/dd/yyyy') ?? null,
      statusMappingId: action.StatusMappingId ?? action.statusMappingId ?? null,
      closureDate: this.datePipe.transform(action.ClosureDate ?? action.closureDate, 'MM/dd/yyyy') ?? null,
      isDeleted: action.IsDeleted ?? action.isDeleted ?? false
    };
  }
  
  onCancelEngagementKpi()
  {
   const dataResult = {
    engagementId:this.engagementId,
    kpiId:this.kpiId,
  };
   if ((this._oldEngagementKpi!=JSON.stringify(this._engagementKpi)))
   {
  
    this.confirmationService.confirm({
      message: 'Changes will not be saved, Do you want to Continue?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.viewKpiButtonClick.emit(dataResult);
        this.messageService.add({
          key: 'tc',
          severity: 'info',
          summary: 'Changes not saved',
          detail: '',
      });
         },
         reject: () => {
         },
       });
   }
   else{
     this.viewKpiButtonClick.emit(dataResult);
   }
    
  }
  onStartDateSelection(startDate:any,type:number){
    this.hyperCareMinEndDate= new Date(startDate);
    this.hyperCareMinEndDate.setDate(this.hyperCareMinEndDate.getDate() + 1);
  }
 
  onEndDateSelection(endDate: any, type: number) {
    const newDate = new Date(endDate);
    newDate.setDate(newDate.getDate() - 1);
    if (type === 1) {
      this.KpiMaxStartDate = newDate;
    } else {
      this.hyperCareMaxStartDate = newDate;
    }
  }

  addRow(type: number) {
    const newRow = {
      Id: 0,
      percentageValue: '',
      mathematicalSymbolId: 0,
      engagementKPIId: 0
    };
  
    if (type === 1) {
      this._dynamicKpiHyperCareThresholds.push(newRow);
    } else {
      this._dynamicKpiSteadyStateThresholds.push(newRow);
    }
  }
  removeRow(index: number, type: number) {
    let targetArray: any[];
    let valueControlName: string;
    let thresholdControlName: string;
  
    if (type === 1) {
      //targetArray = this._dynamicKpiHyperCareThresholds;
      this._dynamicKpiHyperCareThresholds.splice(index, 1);
      valueControlName = '_dyhyperCareThresholdValue';
      thresholdControlName = '_dyhyperCareThreshold';
    } else {
      //targetArray = this._dynamicKpiSteadyStateThresholds;
      this._dynamicKpiSteadyStateThresholds.splice(index, 1);
      valueControlName = '_dysteadyStateThresoldValue';
      thresholdControlName = '_dysteadyStateThreshold';
    }
  
    //targetArray.splice(index, 1);
  
    this.myEngagementKPIForm.controls[valueControlName].setErrors(null);
    this.myEngagementKPIForm.controls[thresholdControlName].setErrors(null);
    this.resetKpiThreshold(type);
  }
  

  resetKpiThreshold(type: number) {
    if (type === 1) {
      this._dynamicKpiHyperCareThreshold = new EngagementKPIHyperCareThreshold();
    } else {
      this._dynamicKpiSteadyStateThreshold = new EngagementKPIHyperCareThreshold();
    }
  } 
  
 
  FindEngagementDataById(EngagementId:number){
    this.engagementService.GetEngagementById(EngagementId).subscribe(
      res=>{
        this.EngagementName=res.data.engagementName;
        this.suggestedKPIName=this.EngagementName;
        this.getCombinedContacts(res.data.supplierId,res.data.sponsorId);
      }
    );
  }
  canExist(): boolean | Promise<boolean> | Observable<boolean> {
    if (this.executeCanExist &&(this._oldEngagementKpi!=JSON.stringify(this._engagementKpi)))
    {
      return new Promise<boolean>((resolve) => {
        this.confirmationService.confirm({
          message: 'Changes will not be saved, Do you want to Continue?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: 'Changes not saved',
              detail: '',
          });
            resolve(true); 
          },
          reject: () => {
            resolve(false); 
          },
        });
      });
    }
    else{
      return true;
    }
    
  }
  generateBreadcrumb(component?:string,recordName?:string,tabName?:string,childRecName?:string,recordId?:number)
  {
    this.breadcrumbService.generateBreadcrumb(component,recordName,tabName,childRecName,recordId);
  }
  
  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }
  formOldEngagementKpi(){
    setTimeout(() => {
      this._oldEngagementKpi=JSON.stringify(this._engagementKpi);
      this._iskpiEdit=true;
    }, 2000);
  }
  onNumericInput(event: Event,_controlname:string) {
    const inputElement = event.target as HTMLInputElement;
    const sanitizedValue = inputElement.value.replace(/\D/g, '');
    inputElement.value = sanitizedValue;
    this.myEngagementKPIForm.controls[_controlname].setValue(sanitizedValue);
  }

  onNumericInput_ForTargetValue(event: Event,_controlname:string) {
    const sanitizedValue= this.commonService.onNumericInput_ForTargetValue_KPI(event);
    this.myEngagementKPIForm.controls[_controlname].setValue(sanitizedValue);
  }
  

  oldKpiInfo:any;
  previousEndDate:any;
  DataCollectionFrequency:string="";
  isAttribute1NotApplicable=false;
  isAttribute2NotApplicable=false;
  isAttribute3NotApplicable=false;
    FindKpiById(SupKpiId:number) {
    this.loaderService.showLoader();
      this.service.GetEngagementKpiById(SupKpiId).subscribe(
      res=>{
        this._engagementKpi=res.data;
       this.previousEndDate=res.data.kpiEndDate;
        this._engagementKpi.kpiStartDate=new Date(res.data.kpiStartDate);
        this._engagementKpi.kpiEndDate=new Date(res.data.kpiEndDate);

        

        this._engagementKpi.hyperCareStartDate=new Date(res.data.hyperCareStartDate);
        this._engagementKpi.hyperCareEndDate=new Date(res.data.hyperCareEndDate);

          if(this._engagementKpi.attribute1 != 'NA' && this._engagementKpi.attribute1 != "")
            {
            this.isAttribute1NotApplicable=false;
            }
            else{
              this.isAttribute1NotApplicable=true;
            }
            if(this._engagementKpi.attribute2 != 'NA' && this._engagementKpi.attribute2 != "")
              {
              this.isAttribute2NotApplicable=false;
              }
              else{
                this.isAttribute2NotApplicable=true;
              }
              if(this._engagementKpi.attribute3 != 'NA' && this._engagementKpi.attribute3 != "")
                {
                this.isAttribute3NotApplicable=false;
                }
                else{
                  this.isAttribute3NotApplicable=true;
                }
        

        this._readOnlyCalculation=res.data.calculation?res.data.calculation:"";
        this._readOnlyAttribute1=res.data.attribute1?res.data.attribute1:"";
        this._readOnlyAttribute2=res.data.attribute2?res.data.attribute2:"";
        this._readOnlyAttribute3=res.data.attribute3?res.data.attribute3:"";


        this._engagementKpiDataCollections=res.data.engagementDataCollectionList;
        this._oldengagementKpiDataCollections=res.data.engagementDataCollectionList;
        this._engagementKpiDataCollections.forEach((item: any) => {
          item.findingsCount = item.engagementKpiMitigationActionsList ? item.engagementKpiMitigationActionsList.length : 0;
        });
        
        this.FindAllkpiDataCollectionFrequenies(8);
       
        this._overAllAverageKPI=res.data.overAllAverageKPI;
        this._overallAverageHealth=res.data.overallAverageHealth;
        this.kpiCreatedUserName=this.privilegeService.getUserName(res.data.createdBy);
        this.kpiUpdatedUserName=this.privilegeService.getUserName(res.data.updatedBy);
        if(res.data.engagementKPIHyperCareThreshold?.length==1)
        {
        this._kpiHyperCareThreshold=res.data.engagementKPIHyperCareThreshold[0];
        }
        else if(res.data.engagementKPIHyperCareThreshold?.length==2){
        this._kpiHyperCareThreshold=res.data.engagementKPIHyperCareThreshold[0];
        this._dynamicKpiHyperCareThresholds.push(res.data.engagementKPIHyperCareThreshold[1]);
        this._dynamicKpiHyperCareThreshold=res.data.engagementKPIHyperCareThreshold[1];
        }
        else{
         this._isNotApplicable=true;
         this._engagementKpi.hyperCareStartDate=undefined;
         this._engagementKpi.hyperCareEndDate=undefined;
        }
        if(res.data.engagementKPISteadyStateThreshold?.length<2)
        {
        this._kpiSteadyStateThreshold=res.data.engagementKPISteadyStateThreshold[0];
        }
        else{
        this._kpiSteadyStateThreshold=res.data.engagementKPISteadyStateThreshold[0];
        this._dynamicKpiSteadyStateThresholds.push(res.data.engagementKPISteadyStateThreshold[1]);
        this._dynamicKpiSteadyStateThreshold=res.data.engagementKPISteadyStateThreshold[1];
        }
        setTimeout(() => {
          this.OnChangeKPIName(res.data.kpiDetailId);
          let filteDataCollection = this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==this._engagementKpi.kpiDataCollectionFrequencyId);
          if(filteDataCollection!=null)
          {
          this.DataCollectionFrequency=filteDataCollection[0].name;
          let selectedSubCode=filteDataCollection[0].sub_Code;
          this.kpiReportingFrequencyDataSource=this.kpiReportingFrequencyDataSource?.filter(t=>t.sub_Code>=selectedSubCode) ;
          }
         
        },1000);
        this.oldKpiInfo=res.data;
        this. oldKpiInfo.dataCollectionList=res.data.engagementDataCollectionList;
        this. oldKpiInfo.overAllAverageKPI=res.data.overAllAverageKPI;
        this. oldKpiInfo.overallAverageHealth=res.data.overallAverageHealth
        this.oldEngaementKPIData=JSON.stringify(this.oldKpiInfo);
        this.oldKpiInfo.kpiEndDate = new Date(this.oldKpiInfo.kpiEndDate).toLocaleDateString(); 
        this.engagementKPIDetailBeforeUpdate=JSON.stringify(this.oldKpiInfo);
        setTimeout(() => {
          this.loaderService.hideLoader();
        },1000);
      }
    );
    
  };

  SetFrequencyValueforDataCollection(frequencyId:any){
    let frequency=this.kpiDataCollectionFrequencyDataSource?.filter(t=>t.id==frequencyId)[0].name;
        
      this._engagementKpiDataCollections.forEach((item:any) => {
      item.dataCollectionFrequencyValue=this.commonService.getFrequencyFromDate(item.dataCollectionDate,frequency);
    });
  }

  BindFrequencyDates(startFreq: any, endFreq: any, frequency: any, dataCollectionFrequencyData:any) 
  {
    let startFrequencyValue: string;
    let startFrequencyYear: string;
    let endFrequencyValue: string;
    let endFrequencyYear: string;
  
    switch (frequency) {
      case "Monthly":
        [startFrequencyValue, startFrequencyYear] = startFreq.split(" ");
        [endFrequencyValue, endFrequencyYear] = endFreq.split(" ");
        break;
      case "Quarterly":
      case "Half-Year":
      case "Annual":
        startFrequencyValue = startFreq.substring(0, 2); // Extract the first two characters
        startFrequencyYear = startFreq.slice(-4);
        endFrequencyValue = endFreq.substring(0, 2); // Extract the first two characters
        endFrequencyYear = endFreq.slice(-4);
        break;
      default:
        throw new Error('Invalid frequency');
    }

    if(frequency != "Annual"){
      this._engagementKpi.frequencyValueforStartDate=dataCollectionFrequencyData.find((a:any)=>a.name==startFrequencyValue).order;
      this._engagementKpi.frequencyValueforEndDate=dataCollectionFrequencyData.find((a:any)=>a.name==endFrequencyValue).order;;
    }

  this._engagementKpi.kpiStartDateYear=startFrequencyYear;
  this._engagementKpi.kpiEndDateYear=endFrequencyYear;
    
  }
  //Data collection SubGridOperations
  isAttributeValueSelected=false;
  
  onRadioButtonClick(KPIoption: string) {
    this.isAttributeValueSelected = KPIoption === '2';
    this.dataCollectionSubmitted = false;
  
    const resetAttributes = () => {
      this._engagementKpiDataCollection.attribute1 = null;
      this._engagementKpiDataCollection.attribute2 = null;
      this._engagementKpiDataCollection.attribute3 = null;
      if (this._readOnlyFormulaName !== 'N/A') {
        this._engagementKpiDataCollection.calculatedKPI = "";
      }
    };
  
    const setErrorsAndDisable = (shouldDisable: boolean) => {
      const action = shouldDisable ? 'disable' : 'enable';
      const errors = shouldDisable ? null : { required: true };
  
      this.kpiDataCollectionFrom.controls['_attribute1'].setErrors(errors);
      this.kpiDataCollectionFrom.controls['_attribute2'].setErrors(errors);
      this.kpiDataCollectionFrom.controls['_attribute3'].setErrors(errors);
      this.kpiDataCollectionFrom.controls['_calculatedKPI'].setErrors(errors);
  
      this.kpiDataCollectionFrom.get('_attribute1')?.[action]();
      this.kpiDataCollectionFrom.get('_attribute2')?.[action]();
      this.kpiDataCollectionFrom.get('_attribute3')?.[action]();
      this.kpiDataCollectionFrom.get('_calculatedKPI')?.[action]();
    };
  
    const enableSpecificAttributes = () => {
      if (this._readOnlyAttribute1 !== '' && this._readOnlyFormulaName !== 'N/A') {
        this.kpiDataCollectionFrom.controls['_attribute1'].setErrors({ required: true });
      }
      if (this._readOnlyAttribute2 !== '' && this._readOnlyFormulaName !== 'N/A') {
        this.kpiDataCollectionFrom.controls['_attribute2'].setErrors({ required: true });
      }
      if (this._readOnlyAttribute3 !== '' && this._readOnlyFormulaName !== 'N/A') {
        this.kpiDataCollectionFrom.controls['_attribute3'].setErrors({ required: true });
      }
    };
  
    switch (KPIoption) {
      case '1':
        resetAttributes();
        setErrorsAndDisable(true);
        this.kpiDataCollectionFrom.controls['_calculatedKPI'].setErrors({ required: true });
        this.kpiDataCollectionFrom.get('_calculatedKPI')?.enable();
        break;
  
      case '2':
        resetAttributes();
        setErrorsAndDisable(false);
        enableSpecificAttributes();
        break;
  
      default:
        resetAttributes();
        setErrorsAndDisable(true);
        break;
    }
  }
  
  
  editDataCollection(item: any) {
    this._engagementKpiDataCollection = new EngagementKpiDataCollection();
    this.dataCollectionSubmitted = false;
    this.visible = true;
    this.selectedItem = { ...item };
    this.oldSelectedItem = { ...item };
  
    const resetAttributes = () => {
      for (let i = 1; i <= 3; i++) {
        const attributeName = `attribute${i}`;
        this.selectedItem[attributeName] = this.selectedItem[attributeName] ? this.selectedItem[attributeName] : null;
      }
    };
  
    if (this._kpiAttributeTypeCode === "INT") {
      if (this._engagementKpiDataCollection.inputAttributeValueType == 1) {
        this.kpiDataCollectionFrom.get('_calculatedKPI')?.enable();
      }
  
      this.selectedItem.attribute1 = this.selectedItem.attribute1 || "";
      this.selectedItem.attribute2 = this.selectedItem.attribute2 || "";
      this.selectedItem.attribute3 = this.selectedItem.attribute3 || "";
      
      this.selectedItem.calculatedKPI = (this.selectedItem.attribute1 === "" && this.selectedItem.calculatedKPI == null) ? "" : this.selectedItem.calculatedKPI;
      
      this._engagementKpiDataCollection = this.selectedItem;
      resetAttributes();
      
      this.kpiDataCollectionFrom.get('_attribute1')?.enable();
      this.kpiDataCollectionFrom.get('_attribute2')?.enable();
  
      this.isAttributeValueSelected = this._engagementKpiDataCollection.inputAttributeValueType == 2;
    }
  
    if (this._kpiAttributeTypeCode === "DTE") {
      this.selectedItem.attribute1 = this.selectedItem.attribute1 ? new Date(this.selectedItem.attribute1) : "";
      this.selectedItem.attribute2 = this.selectedItem.attribute2 ? new Date(this.selectedItem.attribute2) : "";
      this.selectedItem.attribute3 = this.selectedItem.attribute3 ? new Date(this.selectedItem.attribute3) : "";
  
      this.selectedItem.calculatedKPI = (this.selectedItem.attribute1 === "" && this.selectedItem.calculatedKPI == null) ? "" : this.selectedItem.calculatedKPI;
      
      this._engagementKpiDataCollection = this.selectedItem;
  
      if (this._engagementKpiDataCollection.inputAttributeValueType == 1) {
        this.kpiDataCollectionFrom.get('_attribute1')?.disable();
        this.kpiDataCollectionFrom.get('_attribute2')?.disable();
        this.kpiDataCollectionFrom.get('_attribute3')?.disable();
        resetAttributes();
      } else {
        this.kpiDataCollectionFrom.get('_attribute1')?.disable();
        this.kpiDataCollectionFrom.get('_attribute2')?.disable();
        this.kpiDataCollectionFrom.get('_attribute3')?.disable();
        this.kpiDataCollectionFrom.get('_calculatedKPI')?.disable();
        resetAttributes();
      }
    }
  
    if (this._kpiAttributeTypeCode === "NA") {
      this.selectedItem.attribute1 = this.selectedItem.attribute1 || "";
      this.selectedItem.attribute2 = this.selectedItem.attribute2 || "";
      this.selectedItem.attribute3 = this.selectedItem.attribute3 || "";
  
      this.selectedItem.calculatedKPI = this.selectedItem.calculatedKPI == null ? "" : this.selectedItem.calculatedKPI;
      
      this._engagementKpiDataCollection = this.selectedItem;
      this.isAttributeValueSelected = false;
    }
  }
  cancelDialog() {
    this.visible = false;
    this.messageService.add({
      key: 'tc',
      severity: 'info',
      summary: 'KPI data collection has not been saved',
      detail: '',
  });
  }

  isBtnClicked=false;
  btnCalculateKPI(){
    
      // Attribute3 should be considered here when attribute3 will be included in the business case
        if(this._engagementKpiDataCollection.attribute1 != '' && this._engagementKpiDataCollection.attribute2 != ""){
      this.CalculateKPI();
      }
      else
      {
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Enter the required Attribute values', detail: '' });
      }
   
  }

  isSaveKpiDataCollectionPopupBtnDisable = false;
saveKpiDataCollection() {
  this.ensureNonEmptyAttributes();

  this.toggleSaveButtonState(true);
  setTimeout(() => {
    this.toggleSaveButtonState(false);
  }, 3000);

  this.clearErrorsBasedOnAttributeValueType();

  this.dataCollectionSubmitted = true;
  if (this.kpiDataCollectionFrom.valid) {
    this.handleSaveBasedOnAttributeValueType();
  }
}

private toggleSaveButtonState(isDisabled: boolean) {
  this.isSaveKpiDataCollectionPopupBtnDisable = isDisabled;
}

private handleSaveBasedOnAttributeValueType() {
  if (this._engagementKpiDataCollection.inputAttributeValueType === 2) {
    this.isBtnClicked = true;
    this.CalculateKPI();
  } else {
    this.saveLocalDataCollection();
  }
}

ensureNonEmptyAttributes() {
  this._engagementKpiDataCollection.attribute1 = this._engagementKpiDataCollection.attribute1 || "0";
  this._engagementKpiDataCollection.attribute2 = this._engagementKpiDataCollection.attribute2 || "0";
  this._engagementKpiDataCollection.attribute3 = this._engagementKpiDataCollection.attribute3 || "0";
}

clearErrorsBasedOnAttributeValueType() {
  const controls = this.kpiDataCollectionFrom.controls;
  
  if (this._engagementKpiDataCollection.inputAttributeValueType === 1) {
    controls['_attribute1'].setErrors(null);
    controls['_attribute2'].setErrors(null);
    controls['_attribute3'].setErrors(null);
  } else if (this._engagementKpiDataCollection.inputAttributeValueType === 2) {
    controls['_calculatedKPI'].setErrors(null);
    
    if (!this._readOnlyAttribute1) {
      controls['_attribute1'].setErrors(null);
    }
    if (!this._readOnlyAttribute2) {
      controls['_attribute2'].setErrors(null);
    }

    controls['_attribute3'].setErrors(null);
    
  } else {
    controls['_attribute1'].setErrors(null);
    controls['_attribute2'].setErrors(null);
    controls['_attribute3'].setErrors(null);
    controls['_calculatedKPI'].setErrors(null);
  }
}

saveLocalDataCollection() {
  const KpiDataCollectionIndex = this.findKpiDataCollectionIndex();
  
  if (KpiDataCollectionIndex !== -1) {
    this.updateSelectedItemAttributes();
    this.updateSelectedItem();

    this._engagementKpiDataCollections[KpiDataCollectionIndex] = { ...this.selectedItem };

    this.updateEditedKPIDataCollection();
    this.clearSelection();
  }

  this.finalizeSave();
}

private findKpiDataCollectionIndex(): number {
  return this._engagementKpiDataCollections.findIndex(item => item.id === this.oldSelectedItem.id);
}

private updateSelectedItem() {
  this.selectedItem = {
    ...this.selectedItem,
    calculatedKPI: this._engagementKpiDataCollection.calculatedKPI || null,
    health: this._engagementKpiDataCollection.health,
    kpiAttributeTypeId: this._kpiAttributeTypeId
  };
}

private finalizeSave() {
  this.visible = false;
  this.messageService.add({
    key: 'tc',
    severity: 'success',
    summary: 'KPI data collection has been saved',
    detail: '',
  });
}


updateSelectedItemAttributes() {
  if (this._kpiAttributeTypeCode === "INT") {
    this.selectedItem.attribute1 = this._engagementKpiDataCollection.attribute1;
    this.selectedItem.attribute2 = this._engagementKpiDataCollection.attribute2;
    this.selectedItem.attribute3 = this._engagementKpiDataCollection.attribute3;
  } else if (this._kpiAttributeTypeCode === "DTE") {
    const format = "dd-MMM-yyyy";
    this.selectedItem.attribute1 = this.datePipe.transform(this._engagementKpiDataCollection.attribute1, format);
    this.selectedItem.attribute2 = this.datePipe.transform(this._engagementKpiDataCollection.attribute2, format);
    this.selectedItem.attribute3 = this.datePipe.transform(this._engagementKpiDataCollection.attribute3, format);
  }
}

updateEditedKPIDataCollection() {
  const index = this.editedKPIDataCollection.findIndex((obj: { id: any; }) => obj.id === this.selectedItem.id);

  if (index !== -1) {
    this.editedKPIDataCollection.splice(index, 1);
  }
  
  this.editedKPIDataCollection.push(this.selectedItem);
}

clearSelection() {
  this.selectedItem = null;
  this.oldSelectedItem = null;
}

onNumericInputpopUp(event: Event, _controlname: string) {
  const inputElement = event.target as HTMLInputElement;
  let sanitizedValue = inputElement.value.replace(/[^\d.]/g, ''); // Allow only digits and a single dot
  const decimalParts = sanitizedValue.split('.'); // Split value by dot

  // If there are more than 2 decimal parts, keep only the first two parts
  if (decimalParts.length > 1) {
    sanitizedValue = decimalParts[0] + '.' + (decimalParts[1] || '').slice(0, 2); // Keep only up to two decimal places
  }

  inputElement.value = sanitizedValue;
  this.kpiDataCollectionFrom.controls[_controlname].setValue(sanitizedValue);
}

calculatedFieldValue:any;
CalculateKPI(){
  this._engagementKpiDataCollection.attribute1=this._engagementKpiDataCollection.attribute1?.toString();
  this._engagementKpiDataCollection.attribute2=this._engagementKpiDataCollection.attribute2?.toString();
  if(this._engagementKpiDataCollection.attribute1!="" && this._engagementKpiDataCollection.attribute2!="" && this._engagementKpiDataCollection.attribute1!=null && this._engagementKpiDataCollection.attribute2!=null)
  {
   this.loaderService.showLoader();

  this._contractKPIService.CalculateKPI_New(this._formulaeId,this._engagementKpiDataCollection.attribute1,this._engagementKpiDataCollection.attribute2,this._engagementKpiDataCollection.attribute3,'EngagementKPI',this._kpiAttributeTypeCode)
   .then(calculatedKPI => {

    if(calculatedKPI.toString().toLocaleLowerCase()!='infinity')
   {
    if(this.isBtnClicked){
      this.isBtnClicked=false;
     this._engagementKpiDataCollection.calculatedKPI=calculatedKPI;
      this.saveLocalDataCollection();
    }
    else{
      this._engagementKpiDataCollection.calculatedKPI=calculatedKPI;
     }
    }
    else{
      this.isBtnClicked=false;
      this.messageService.add({ key: 'tc', life: 5000, severity: 'error', summary: 'The values you entered for the attributes are resulting in an invalid output. Please re-enter the attribute values.', detail: '' });
    }
  
       this.loaderService.hideLoader();
   })
   .catch(error => {
    this._engagementKpiDataCollection.calculatedKPI=0;
   });
  
 }
  setTimeout(() => {
   this.finalClear();
 },2000);
 
}

finalClear()
{
 if((this._engagementKpiDataCollection.attribute1== "" || this._engagementKpiDataCollection.attribute2=="") && this._engagementKpiDataCollection.inputAttributeValueType==2)
 {
  this._engagementKpiDataCollection.calculatedKPI="";
 }
}


onAttributeDateSelection(attributeDate:any,type:number){
  if(this._engagementKpiDataCollection.calculatedKPI=="")
  {
    this.dataCollectionSubmitted =false
  }
  if(this._engagementKpiDataCollection.attribute1!="" && this._engagementKpiDataCollection.attribute2!="" && this._engagementKpiDataCollection.attribute1!=null && this._engagementKpiDataCollection.attribute2!=null &&this.dataCollectionSubmitted ==false)
   {
    this.loaderService.showLoader();
    this._contractKPIService.CalculateKPI_New(this._formulaeId,this._engagementKpiDataCollection.attribute1,this._engagementKpiDataCollection.attribute2,this._engagementKpiDataCollection.attribute3,'EngagementKPI',this._kpiAttributeTypeCode)
    .then(calculatedKPI => {
      this._engagementKpiDataCollection.calculatedKPI=calculatedKPI;
        this.loaderService.hideLoader();
    })
    .catch(error => {
      this._engagementKpiDataCollection.calculatedKPI=0;
    });
   }
   
}

OnServiceChange(){
  this.PrepareKPINameSuggestion();
}

PrepareKPINameSuggestion() {
  const kpiName = this._engagementKpi.kpiDetailId ? this.kpiNameDataSource?.find((aa: any) => aa.id === this._engagementKpi.kpiDetailId)?.name : '';
  const serviceName = this._engagementKpi.serviceName || '';

  if (kpiName && serviceName) {
    this.suggestedKPIName = `${this.EngagementName}-${kpiName}-${serviceName}`;
  } else if (kpiName) {
    this.suggestedKPIName = `${this.EngagementName}-${kpiName}`;
  } else if (serviceName) {
    this.suggestedKPIName = `${this.EngagementName}-${serviceName}`;
  } else {
    this.suggestedKPIName = this.EngagementName;
  }
}

changedEngagementKPIBasicInfo:any;
    oldEngaementKPIData:any;
    engagementKPIDetailBeforeUpdate:any
    compareModels() {
      if(this._engagementKpi.hyperCareStartDate){
        this._engagementKpi.hyperCareStartDate= new Date(this._engagementKpi.hyperCareStartDate).toISOString();
      }
      if(this._engagementKpi.hyperCareEndDate){
        this._engagementKpi.hyperCareEndDate= new Date(this._engagementKpi.hyperCareEndDate).toISOString();
      }    
      this._engagementKpi.kpiStartDate= new Date(this._engagementKpi.kpiStartDate).toISOString();
      this._engagementKpi.kpiEndDate= new Date(this._engagementKpi.kpiEndDate).toLocaleDateString();

      this.engagementKPIDetailBeforeUpdate=JSON.parse(this.engagementKPIDetailBeforeUpdate);
      this.engagementKPIDetailBeforeUpdate.engagementId=this.engagementKPIDetailBeforeUpdate.engagementId.toString();
      this.engagementKPIDetailBeforeUpdate=JSON.stringify(this.engagementKPIDetailBeforeUpdate);

      this.changedEngagementKPIBasicInfo = this.auditLogService.compareModels(JSON.parse(this.engagementKPIDetailBeforeUpdate),this._engagementKpi,this.auditEntities.EngagementKPIs,this.auditIdentifierDetails.EngagementKPIs,this.auditOperations.Update,this.auditModule.GovernanceService);
    }



    getCalculationFormulae(){
      //this._readOnlyFormulaName
      let data=this._readOnlyFormulaName;
      data=data.replace('Attribute 1',this._readOnlyAttribute1);
      data=data.replace('Attribute 2',this._readOnlyAttribute2);
      data=data.replace('Attribute 3',this._readOnlyAttribute3);
      this._readOnlyCalculation=data;
     }
     currencies: any='USD';
     getFormattedValue(value: number, currencyName: string): string {
     let formattedValue = this.currencyService.getFormattedValue(value, currencyName);
    return this.handleCurruncy(formattedValue);
    }
    private handleCurruncy(formattedValue: string): string {
      return formattedValue.replace(/^[^\d]+/, '');
  }
   
  selectedKpiReportData: any;
  findingsCount=2
displayMitigationListPopup: boolean = false;
displayViewMitigationPopup: boolean = false;
oldMitigationActionsData: any[] = [];
position: string = 'center';
  viewFindingList(Kpidata: any) {
    this.selectedKpiReportData = Kpidata;
    this.addMitigationActionsData = Kpidata.engagementKpiMitigationActionsList ? Kpidata.engagementKpiMitigationActionsList.filter((item: { isDeleted: any; }) => !item.isDeleted)
        .map((item: { targetDate: string; TargetDate: string; closureDate: string; ClosureDate: string; }) => {
       const formattedTargetDate = item.targetDate ? this.convertDateFormat(item.targetDate) : item.TargetDate ? this.convertDateFormat(item.TargetDate) : null;
      const formattedClosureDate = item.closureDate ? this.convertDateFormat(item.closureDate) : item.ClosureDate ? this.convertDateFormat(item.ClosureDate) : null;
  
      return {
        ...item,
        TargetDate: formattedTargetDate ? new Date(formattedTargetDate) : null,
        ClosureDate: formattedClosureDate ? new Date(formattedClosureDate) : null,
      };
    }) : [];
  
    // Update other properties as needed
    this.addMitigationActionsData.forEach(item => {
      item.Id = item.id || item.Id;
      item.KpiId = item.kpiid || item.KpiId;
      item.DataCollectionId = item.dataCollectionId || item.DataCollectionId;
      item.Action = item.action || item.Action;
      item.ActionOwner = item.actionOwnerId || item.ActionOwner;
      item.StatusMappingId = item.statusMappingId || item.StatusMappingId;
      item.TargetDate= item.targetDate!=null ? new Date(item.targetDate) : null  || item.TargetDate!=null ? new Date(item.TargetDate) : null ;
      item.ClosureDate= item.closureDate!=null ? new Date(item.closureDate) : null  || item.ClosureDate !=null ? new Date(item.ClosureDate) : null ;
    });
    this.oldMitigationActionsData = JSON.parse(JSON.stringify(this.addMitigationActionsData));
    this.visableMitigations = this.addMitigationActionsData.filter(action => !action.isDeleted);
    this.selectedKpiReportData.findingsCount = this.addMitigationActionsData.length;
    this.displayMitigationListPopup = true;
    this.addMitigationSaved = false;
  } 
  cancelMitigationDialog() {
    if (JSON.stringify(this.oldMitigationActionsData) !== JSON.stringify(this.addMitigationActionsData)) {
      this.confirmationService.confirm({
        message: 'Changes will not be saved, Do you want to Continue?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: 'Changes not saved',
            detail: '',
          });
          this.displayMitigationListPopup = false;
        },
        reject: () => {
        },
      });
    } else {
      this.displayMitigationListPopup = false;
    }
    }

  
  addMitigationActionsData: any[] = [];
  AddMitigationActions() { //Click on Add Action button new row will be generated on top of the grid
    this.mitigationForm.reset();
    this.addMitigationActionsData.unshift({
      Id: 0,
      Action: '',
      ActionOwner: "",
      TargetDate: null,
      StatusMappingId: '',
      ClosureDate: null,
      IsDeleted: false
    });
    this.visableMitigations = this.addMitigationActionsData.filter(action => !action.isDeleted);
  }
  visableMitigations:any;
  deleteAction(currentActionIndex: number) { 
    this.visableMitigations=this.addMitigationActionsData; 
    this.confirmationService.confirm({
         header: 'Confirm',
         message: 'Are you sure you want to delete this action?',
         accept: () => {
          this.visableMitigations.splice(currentActionIndex,1);
          this.addMitigationActionsData=this.visableMitigations;
         this.messageService.add({ severity: 'success', summary: 'Mitigation Action Deleted', detail: '', });
         }
       });
     }

     supplierContactsDataSource : SupplierContact[]=[];
     sponsorContactsDataSource : SponsorContact[]=[];
     GetAllsupplierContacsById(supplierId:number=0) {
   
      this.supplierService.GetSupplierById(supplierId).subscribe(res => {
          this.supplierContactsDataSource=res.data.supplierContacts;
      });
    
    }
    GetAllSponsorContactsById(sponsorId:number=0) {
      this.masterDataService.getSponserData(sponsorId).subscribe(res=>{
        this.sponsorContactsDataSource= res.data.sponserContacts;
      });
    }
    combinedContacts: any[] = [];
    getCombinedContacts(supplierId: number = 0, sponsorId: number = 0) {
      this.supplierService.GetSupplierById(supplierId).subscribe(supplierRes => {
        this.supplierContactsDataSource = supplierRes.data.supplierContacts;        
        this.masterDataService.getSponserData(sponsorId).subscribe(sponsorRes => {
          this.sponsorContactsDataSource = this.mapSponsorContacts(sponsorRes.data.sponserContacts);
          this.combinedContacts = this.combineContacts();
        });
      });
    }
    
    private mapSponsorContacts(sponsorContacts: any[]): any[] {
      return sponsorContacts.map(contact => ({
        id: contact.id,
        contactName: contact.name,
        countryCode: contact.countryCode,
        designation: contact.designation,
        phoneNumber: contact.phoneNumber,
        email: contact.email,
        supplierProfileId: contact.sponserId,
        createdBy: contact.createdBy,
        createdDate: contact.createdDate,
        updatedBy: contact.updatedBy,
        updatedDate: contact.updatedDate,
        isDeleted: contact.isDeleted
      }));
    }
    
    private combineContacts(): any[] {
      return [...this.supplierContactsDataSource, ...this.sponsorContactsDataSource];
    }
    
  
  actionStatus:any;
  GetMitigationStatus(enumId: number, type: string) { //Get Meeting Status's from code list for the Meetings
    this.masterDataService.getCodeListData(enumId, type).subscribe(
      res => {      
        if (res.data.length > 0) {
          this.actionStatus = res.data;
        }
      }
    );
  };
  _addActionsFieldsMandatory: boolean = false;
    saveMitigationActions() {
    this.addMitigationSaved = true;
  this._addActionsFieldsMandatory = this.addMitigationActionsData.some((action) => {
    const status = this.actionStatus.find((a: { id: any; }) => a.id === action.StatusMappingId);
    if (
      this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(action.Action) || 
      this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(action.StatusMappingId) || 
      (status && status.code === "CLD" && this.checkNullOrEmptyValuesService.isNullOrUndefinedOrEmpty(action.ClosureDate))
    ) {
      return true;
    }
    return false;
  });
  
    if (this.validateFields()) {
    this.selectedKpiReportData.engagementKpiMitigationActionsList = this.addMitigationActionsData.map(action => ({
      ...action,
      StatusMappingId: action.StatusMappingId, 
      ActionOwner: action.ActionOwner === '' ? 0 : action.ActionOwner,
      TargetDate: action.TargetDate || '', 
      ClosureDate: action.ClosureDate || '',
      isDeleted: action.isDeleted || false, 
    }));

    this._engagementKpiDataCollections.forEach(collection => {
      if (collection.engagementKpiMitigationActionsList) {
          collection.engagementKpiMitigationActionsList = collection.engagementKpiMitigationActionsList
              .map(action => this.updateMitigations(action)); // Include all actions
      }
  });

    this.selectedKpiReportData.findingsCount = this.addMitigationActionsData.filter(action => !action.isDeleted).length; 
    this.displayMitigationListPopup = false;
    
  } else {
        Object.values(this.mitigationForm.controls).forEach(control => {
      control.markAsTouched();
    });
  }
  }
  validateFields(): boolean {
    for (const actionData of this.addMitigationActionsData) {
      const status = this.actionStatus.find((s: { id: any; }) => s.id === actionData.StatusMappingId);
      if (!actionData.Action || actionData.Action.trim() === '') {
        return false;
      }
      if (!actionData.StatusMappingId) {
        return false;
      }
      if (status && status.code === "CLD") {
        if (!actionData.ClosureDate) {
          return false;
        }
      }
    }
    return true;
  } 
  convertDateFormat(inputDateTime: string) {
    const date = new Date(inputDateTime);
    const formattedDate = this.datePipe.transform(date, this._dateformart.AuditFormat);
    return formattedDate;
  }
  oldKpiData: any;
  newKpiData: any;
  mitigationDataCollection: any[] = [];
  newMitigation:any;
async compareMitigations() {
  this.oldKpiData = JSON.parse(this.engagementKPIDetailBeforeUpdate);
  this.newKpiData = this._engagementKpi;
  const oldDataCollectionList = this.oldKpiData.dataCollectionList;
  const newDataCollectionList = this.newKpiData?.dataCollectionList;

  oldDataCollectionList.forEach((oldData: { engagementKpiMitigationActionsList: any; dataCollectionDate: any; }, index: number) => {
    const newData = newDataCollectionList ? newDataCollectionList[index] : null;

    if (oldData && newData) {
      const oldMitigations = oldData.engagementKpiMitigationActionsList;
      const newMitigations = newData.engagementKpiMitigationActionsList;
      if (oldMitigations.length === 0 && newMitigations.length !== 0) {
        this.addNewMitigations(newMitigations, newData);
      } else {
        this.processMitigationDifferences(oldMitigations, newMitigations, newData);
      }
    }
  });

  if (this.mitigationDataCollection.length > 0) {
    await this.notificationService.KpiMitigationNotification(
      0, 
      this.mitigationDataCollection, 
      this.EngKpiId, 
      this.SponsorId, 
      this.SupplierId
    );
  }
}

private addNewMitigations(newMitigations: any[], newData: any) {
  newMitigations.forEach((newMitigation: any) => {
    this.mitigationDataCollection.push(this.createMitigationEntry(newMitigation, newData));
  });
}
private processMitigationDifferences(oldMitigations: any[], newMitigations: any[], newData: any) {
  const filterNewMitigations = newMitigations.filter((t: { id: number; }) => t.id === 0);
  filterNewMitigations.forEach((newMitigation: any) => {
    this.mitigationDataCollection.push(this.createMitigationEntry(newMitigation, newData));
  });

  const newMitigationsMap = new Map(newMitigations.map((mitigation: any) => [mitigation.id, mitigation]));
  oldMitigations.forEach((oldMitigation: any) => {
    const newMitigation = newMitigationsMap.get(oldMitigation.id);
    if (newMitigation) {
      const oldStatusCode = this.getStatusCode(oldMitigation.statusMappingId);
      const newStatusCode = this.getStatusCode(newMitigation.statusMappingId);

      if (oldStatusCode === 'OPN' && newStatusCode === 'CLD') {
        this.mitigationDataCollection.push(this.createMitigationEntry(newMitigation, newData));
      }
    }
  });
}

createMitigationEntry(mitigation: any, data: any) {
  return {
    'kpiName': this.newKpiData.kpiName,
    'engagementName': this.EngagementName,
    'mitigationactionstatus': this.actionStatus?.find((aa: any) => aa.id === mitigation?.statusMappingId)?.name,
    'engagementId': this.engagementId,
    'contractTitle': '',
    'Datafor': data.dataCollectionFrequencyValue,
    'kpiValue': data.calculatedKPI === null ? '--' : data.calculatedKPI + '' + this._readOnlySybmolSteadyStateThreshold,
    'Actionfieldvalue': mitigation.action,
    'contractId': '',
    'id': mitigation.id,
    'ownerEmail':this.getEmailById(mitigation.actionOwnerId),
  };
}
 getStatusCode(statusMappingId: any) {
  return this.actionStatus?.find((aa: any) => aa.id === statusMappingId)?.code;
}
  isClosedStatus(statusMappingId: number): boolean {
    const status = this.actionStatus.find((s: { id: number; }) => s.id === statusMappingId);
    return status ? status.code === "CLD" : false;
  }

  
  navigateTo(index: number): void {
    if (index >= 0 && index < this.listOfKpis.length) {
      this.EngKpiId=this.listOfKpis[index].id;
      this.kpiId=this.EngKpiId;
      this.FindKpiById(this.EngKpiId);
      this.currentIndex=index;
      this.formOldEngagementKpi();
      this.DisableNxtPreButton();
    }
  }
  nextRecord(): void {
    this.navigateTo(this.currentIndex + 1);
  }
  previousRecord(): void {
    this.navigateTo(this.currentIndex - 1);
  }
  getCurrentPositionText(): string {
    return `${this.currentIndex + 1} of ${this.listOfKpis.length}`;
  }
  pageChangeConfirmation(buttonType:number):void{
    if((this._oldEngagementKpi==JSON.stringify(this._engagementKpi)))
    {
      buttonType==1?this.nextRecord():this.previousRecord();
    }
    else{
      this.confirmationService.confirm({
        message: 'Changes will not be saved, Do you want to Continue?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: 'Changes not saved',
            detail: '',
        });
         buttonType==1?this.nextRecord():this.previousRecord();
        },
        reject: () => {
      
        },
      });
    }
  }
  buttonDisabled:boolean = false;
DisableNxtPreButton()
{
  this.buttonDisabled = true;
  setTimeout(() => {
    this.buttonDisabled = false;
  },2000); 
}
  updateValidators(controlName: string) {
    const control = this.myEngagementKPIForm.get(controlName);
    if (control) {
      if (control.value === 'NA') {
        control.clearValidators();
        control.setValidators([Validators.required, this.noOnlySpacesValidator, Validators.pattern(/^[^&%]*$/)]);
      } else {
        control.setValidators([Validators.required, Validators.minLength(3), this.noOnlySpacesValidator, Validators.pattern(/^[^&%]*$/)]);
      }
      control.updateValueAndValidity();
    }
  }
  getEmailById(id: number): string | undefined {
    const contact = this.combinedContacts.find(c => c.id === id);
    if(contact!=undefined)
    return contact?.email;
    else
    return "";
  }
}
